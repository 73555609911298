import React, { useState } from 'react';
import axios from 'axios';
import { excelUpload } from '../redux/actions/postAction';
import { useSelector, useDispatch } from "react-redux";

const ExcelUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError] = useState(null);

    const { auth} = useSelector((state) => state);
    const dispatch = useDispatch();

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!selectedFile) {
            setUploadError('Please select an Excel file to upload.');
            return;
        }

        setIsUploading(true);
        setUploadError(null);

        try {
            const formData = new FormData();
            formData.append('excel_file', selectedFile);

            // const response = await axios.post('http://192.168.0.4:8000/api/excel-upload/', formDataformData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //     },
            // });
            dispatch(excelUpload({ formData, auth}));

            // if (response.status === 200) {
            //     console.log('Data uploaded successfully:', response.data);
            //     // Handle success in your UI (e.g., show a message)
            // } else {
            //     setUploadError('Error uploading file. Please try again.');
            // }
        } catch (error) {
            console.error('Upload error:', error);
            setUploadError('An error occurred. Please check your console.');
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
          class="relative w-72 m-0 block min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-600 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
          type="file"
          id="formFileMultiple"
          onChange={handleFileChange} accept=".xlsx,.xls" />
            <button type="submit" disabled={isUploading}
                 className={`mt-1 ml-40 shadow-sm hover:drop-shadow-xl bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-28 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                 >
                {isUploading ? 'Uploading...' : 'Upload Excel'}
            </button>
            {uploadError && <div className="error">{uploadError}</div>}
        </form>
    );
};

export default ExcelUpload;