import React, { useState, useEffect } from "react";
import { BsCurrencyDollar } from 'react-icons/bs';
import { GoPrimitiveDot, GoDotFill } from 'react-icons/go';
import { IoIosMore } from 'react-icons/io';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

import { Stacked, Pie, Button, LineChart, SparkLine } from '../components';
import { medicalproBranding, recentTransactions, weeklyStats, dropdownData, SparklineAreaData, ecomPieChartData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import product9 from '../data/product9.jpg';
import DatePicker, { setDefaultLocale } from 'react-datepicker';
import moment from 'moment';
import profile from '../data/profile.png';
import { getDashboard } from '../redux/actions/caseAction';
import { useSelector, useDispatch } from "react-redux";

import { MdOutlineSupervisorAccount, MdOutlinePending } from 'react-icons/md';
import { PiSignInBold, PiSignOutBold } from "react-icons/pi";
import { FaFileAlt, FaRegFolderOpen, FaRegUser } from "react-icons/fa";
import { RiFileCloseFill} from "react-icons/ri";
import { TbProgressCheck } from "react-icons/tb";


import { FaPersonWalkingLuggage, FaRegFolderClosed } from "react-icons/fa6";
import { MdDevices } from "react-icons/md";
import { Link, NavLink } from 'react-router-dom';

const DropDown = ({ currentMode }) => (
  <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
    <DropDownListComponent id="time" fields={{ text: 'Time', value: 'Id' }} style={{ border: 'none', color: (currentMode === 'Dark') && 'white' }} value="1" dataSource={dropdownData} popupHeight="220px" popupWidth="120px" />
  </div>
);

const Dashboard = () => {
  const { currentColor, currentMode } = useStateContext();

  const [startDate, setStartDate] = useState(moment().add(-30, 'days').toDate() );
  const [endDate, setEndDate] = useState(moment().toDate());

  const [formattedstartDate, setformattedstartDate] = useState(moment().add(-7, 'days').toDate() );
  const [formattedendDate, setformattedendDate] = useState(moment().toDate());

  const handleChangeStart = (startDate) => (
    setStartDate(startDate )
    )

  const handleChangeEnd = (endDate) => (
    setEndDate(endDate )
    )

    const { auth, cases  } = useSelector((state) => state);
    const dispatch = useDispatch();
 
    useEffect(() => {
      if (auth.token) {
        const formattedstartDate = startDate.toISOString().split("T")[0];
        const formattedendDate = endDate.toISOString().split("T")[0];
        setformattedstartDate(formattedstartDate)
        setformattedendDate(formattedendDate)
        dispatch(getDashboard(formattedstartDate, formattedendDate,auth.token));
      }

    }, [dispatch, startDate,  endDate, auth]);

    const [data, setdata] = useState("");
    const [checkIn, setCheckIn] = useState("");
    const [checkOut, setCheckOut] = useState("");
    const [guards, setGuards] = useState([]);
    const [InCountbyDay, setInCountbyDay] = useState("");
    const [OutCountbyDay, setOutCountbyDay] = useState("");


    useEffect(() => {
      setdata(cases?.dashboard)
      // const data1 = dashboard?.dashboard?.check_date
      // if (data1){
      // const checkindata = data1.filter((item) => item.status == 1);
      // const checkoutdata = data1.filter((item) => item.status == 0);
     
      // handleUsers(data1)

      // handleChart(checkindata, checkoutdata)

      // setCheckIn(checkindata)
      // setCheckOut(checkoutdata)
    // }

    }, [cases]);

    


    // const handleUsers = (data1) => {
    //        const user1 = data1.map((item) => {
    //          const user = item.user;  // Extract the user object
    //          user.status = item.status;  // Add the status property to the user object
    //          return user;
    //          })

    //        // Create an object to store the counts
    //        var result = {};
           
    //        // Loop through the items array
    //        for (var i = 0; i < user1.length; i++) {
    //         var currentItem = user1[i];
            
    //         // Check if the currentItem.id exists in the result object
    //         if (!result[currentItem.id]) {
    //           // If not, create a new object for the currentItem.id
    //           result[currentItem.id] = {
    //             id: currentItem.id,
    //             count_status_out: 0,
    //             count_status_in: 0,
    //             email: currentItem.email,
    //             full_name:currentItem.full_name,
    //             profile_pic:currentItem.profile_pic
    //           };
    //         }
            
    //         // Increment the respective count based on the status value
    //         if (currentItem.status) {
    //           result[currentItem.id].count_status_in++;
    //         } else {
    //           result[currentItem.id].count_status_out++;
    //         }
    //        }
           
         // //  Convert the result object to an array of objects
    //        var finalResult = Object.values(result);

    //        setGuards(finalResult)

    // }

    // const handleChart = (checkindata, checkoutdata) => {
      
    //   const InCountsByDay = {};
    //   const OutCountsByDay = {};

    //   checkindata.forEach((item) => {
    //     const date = new Date(item.created_at);
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const formattedDate = `${year}-${month}-${day}`;
    //     // const day = date.toLocaleDateString();
    //     InCountsByDay[formattedDate] = (InCountsByDay[formattedDate] || 0) + 1;
    //   });

    //   checkoutdata.forEach((item) => {
    //     const date = new Date(item.created_at);
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const formattedDate = `${year}-${month}-${day}`;
    //     OutCountsByDay[formattedDate] = (OutCountsByDay[formattedDate] || 0) + 1;
    //   });
      
    //   setInCountbyDay(InCountsByDay)
    //   setOutCountbyDay(OutCountsByDay)
    //   //console.log("itemCountsByDay",InCountsByDay,OutCountsByDay);


    // }
    

    const dashBoardData = [
      {
        icon: <FaFileAlt />,
        amount: data?.countofAllCases,
        title: 'Total Case',
        iconColor: '#03C9D7',
        iconBg: '#E5FAFB',
        pcColor: 'red-600',
      },
      // {
      //   icon: <FaRegFolderOpen />,
      //   amount: checkOut.length,
      //   // percentage: '+23%',
      //   title: 'Opened Cases',
      //   iconColor: 'rgb(255, 244, 229)',
      //   iconBg: 'rgb(254, 201, 15)',
      //   pcColor: 'green-600',
      // },
      {
        icon: <MdOutlinePending />,
        amount: data?.countofPending,
        // percentage: '-12%',
        title: 'Pending',
        iconColor: 'rgb(0, 194, 146)',
        iconBg: 'rgb(235, 250, 242)',
        pcColor: 'red-600',
        Link: "InPremises",
        Link1:"Pending"
      },
      {
        icon: <TbProgressCheck />,
        amount: data?.countofInProgress,
        // percentage: '-12%',
        title: 'In Progress',
        iconColor: 'rgb(0, 194, 146)',
        iconBg: 'rgb(235, 250, 242)',
        pcColor: 'red-600',
        Link: "InPremises",
        Link1:"In Progress"
      },
      {
        icon: <FaRegFolderClosed />,
        amount: data?.countofProcess_Completed,
        // percentage: '-12%',
        title: 'Process Completed',
        iconColor: 'rgb(255, 244, 229)',
        iconBg: 'rgb(254, 201, 15)',
        pcColor: 'red-600',
        Link: "All_Completed",
        // Link1:"cases"
      },
      {
        icon: <RiFileCloseFill />,
        amount: data?.countofCanceled,
        // percentage: '+38%',
        title: 'Canceled',
        iconColor: 'rgb(228, 106, 118)',
        iconBg: 'rgb(255, 244, 229)',
    
        pcColor: 'green-600',
        Link: "InPremises",
        Link1:"Canceled"
      },
      {
        icon: <FaRegUser />,
        amount: data?.countofAsignedtome,
        // percentage: '-12%',
        title: 'Asigned to Me',
        iconColor: 'rgb(255, 244, 229)',
        iconBg: 'rgb(254, 201, 15)',
        pcColor: 'red-600',
        Link: "InPremises",
        Link1:"Asigned_to_Me"
      },
    ];
    
  
  
  return (
    <div className="mt-24">
       <div className="flex flex-wrap lg:flex-nowrap justify-end items-center gap-5 mr-10 mb-5 ">
          <DatePicker
              selected={startDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={handleChangeStart}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          />
          
          <DatePicker
              selected={endDate}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={handleChangeEnd}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          />
          </div>
      <div className="flex flex-wrap lg:flex-nowrap justify-center items-center ">
        
        

        <div className="flex m-3 flex-wrap justify-center gap-5 items-center">
          {dashBoardData.map((item) => (
            <div key={item.title} className="bg-white h-44 min-w-40 shadow-lg  dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
              <Link
                //  to={`/${item?.Link}/${item?.title} || ''`}
                 to={item.Link && `/${item.Link}${item.Link1 ? `/${item.Link1}` : ''}/${formattedstartDate}/${formattedendDate}`}

                 
                >
              <button
                type="button"
                style={{ color: item.iconColor, backgroundColor: item.iconBg }}
                className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
              >
                {item.icon}
              </button>
              </Link>
              <p className="mt-3">
                <span className="text-lg font-semibold">{item.amount}</span>
                <span className={`text-sm text-${item.pcColor} ml-2`}>
                  {item.percentage}
                </span>
              </p>
              <p className="text-sm text-gray-400  mt-1">{item.title}</p>
            </div>
          ))}
        </div>
      </div>

      


      

      
    </div>
  )
}

export default Dashboard


