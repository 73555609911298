import React, { useEffect, useState } from 'react'

// import { createAction } from '../../redux/actions/caseAction';
import { useSelector, useDispatch } from 'react-redux';
// import { GLOBALTYPES } from '../../redux/actions/globalTypes';

const Timeline = ({action}) => {

  const itemsPerPage = 5; // Number of items to show initially
  const [currentPage, setCurrentPage] = useState(1);
  const [slicedData, setSlicedData] = useState(action.slice(0, itemsPerPage));

  useEffect(() => {
    setSlicedData(action.slice(0, itemsPerPage))
    }, [action]);

  const handleShowMore = () => {
    const nextPage = currentPage + 1;
    const startIndex = (nextPage - 1) * itemsPerPage;
    const newSlicedData = action.slice(startIndex, startIndex + itemsPerPage);

    setCurrentPage(nextPage);
    setSlicedData(slicedData.concat(newSlicedData)); // Efficiently append new data
  };

  const isMoreAvailable = action.length > currentPage * itemsPerPage;

    const covertdate = (date) => {
        const options = {
        //   weekday: 'long',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        //   second: '2-digit',
        };
      
        // Parse the timestamp string into a Date object
        const date1 = new Date(date);
      
        // Use toLocaleDateString() on the Date object
        return date1.toLocaleDateString('en-US', options);
      };

    //   console.log("actionnnnnnnnnnnnnnaaaaaaatimestamp", action)


    return (
        <div>
            {slicedData?.map((data) => (
            <div class="flex gap-x-3">

                <div class="w-28 text-end">
                    <span class="text-base text-gray-500 dark:text-gray-400">{covertdate(data.created_at)}</span>
                </div>

          

                <div class="min-h-24 relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
                    <div class="relative z-10 size-7 flex justify-center items-center">
                        <div class="size-2 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                    </div>
                </div>

                <table size="table-auto">
                <tbody className='float-start mb-4 mt-1'>
                    {data.category && <tr className=' '>
                        <td className="mt-1  pr-2 text-sm text-gray-600 dark:text-gray-400">Category</td>
                        <td className="text-left font-semibold text-gray-800 dark:text-white">
                            {/* <a href="#" className="">Corruption</a> */}
                            {data.category}
                        </td>
                    </tr>}
                    {data.status &&<tr className=''>
                        <td className="mt-1 pr-2  text-sm text-gray-600 dark:text-gray-400">Status</td>
                        <td className="text-left font-semibold text-gray-800 dark:text-white">
                           {data.status}
                        </td>
                    </tr>}
                    
                    {data.priority && <tr className=''>
                        <td className="mt-1 pr-2  text-sm text-gray-600 dark:text-gray-400">Priority</td>
                        <td className="font-semibold text-gray-800 dark:text-white">
                            {data.priority}
                        </td>
                    </tr>}
                   
                    {data.assign_to && <tr className=''>
                        <td className="mt-1 pr-2  text-sm text-gray-600 dark:text-gray-400">assigned_to</td>
                        <td className="font-semibold text-gray-800 dark:text-white">
                            {data.assign_to.full_name}
                        </td>
                    </tr>}

                    {data.remark && <tr className=''>
                        <td className="mt-1 pr-2 text-sm text-gray-600 dark:text-gray-400">Remark :</td>
                        <td className="mt-1 text-left max-w-72 text-sm text-gray-800 dark:text-white">
                        {data.remark}
                        </td>
                    </tr>}

                    {data.editor && <tr className=''>
                        {/* <td className="mt-1 pr-2 text-sm text-gray-600 dark:text-gray-400">Remark :</td> */}
                        <td className="mt-1 text-left max-w-72 text-sm text-gray-800 dark:text-white">
                           writes in editor
                        </td>
                    </tr>}

                    {data.attachments.length > 0 && <tr className=''>
                        {/* <td className="mt-1 pr-2 text-sm text-gray-600 dark:text-gray-400">Remark :</td> */}
                        <td className="mt-1 text-left max-w-72 text-sm text-gray-800 dark:text-white">
                          attached {data.attachments.length}  file
                        </td>
                    </tr>}
                    <button type="button" class="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                        <span class="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400">
                           {data?.user?.full_name.charAt(0).toUpperCase()}
                        </span>
                        {data?.user?.full_name}
                    </button>
                </tbody>
                </table>

                {/* <div class="grow pt-0.5 pb-8">
                    <h3 class="flex gap-x-1.5 font-semibold text-gray-800 dark:text-white">
                        <svg class="flex-shrink-0 size-4 mt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" /><polyline points="14 2 14 8 20 8" /><line x1="16" x2="8" y1="13" y2="13" /><line x1="16" x2="8" y1="17" y2="17" /><line x1="10" x2="8" y1="9" y2="9" /></svg>
                        Created "Preline in React" task
                    </h3>
                    <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">
                                    Find more detailed insctructions here.
                                </p>
                                <button type="button" class="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                                    <image class="flex-shrink-0 size-4 rounded-full" src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8auto=format&fit=facearea&facepad=3&w=320&h=320&q=80" alt="Image Description" />
                                    James Collins
                                </button>
                </div> */}

            </div>))}


            {/* <div class="flex gap-x-3">

                <div class="w-28 text-end">
                    <span class="text-base text-gray-500 dark:text-gray-400">12:05PM</span>
                </div>

                <div class="min-h-10 relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
                    <div class="relative z-10 size-7 flex justify-center items-center">
                        <div class="size-2 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                    </div>
                </div>

                <div class="grow pt-0.5 pb-8">
                    <h3 class="flex gap-x-1.5 font-semibold text-gray-800 dark:text-white">
                        Release v5.2.0 quick bug fix 🐞
                    </h3>
                    <button type="button" class="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                        <span class="flex flex-shrink-0 justify-center items-center size-4 bg-white border border-gray-200 text-[10px] font-semibold uppercase text-gray-600 rounded-full dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400">
                            A
                        </span>
                        Alex Gregarov
                    </button>
                </div>

            </div>


            <div class="flex gap-x-3">

                <div class="w-28 text-end">
                    <span class="text-base text-gray-500 dark:text-gray-400">12:05PM</span>
                </div>


                <div class="min-h-24 relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
                    <div class="relative z-10 size-7 flex justify-center items-center">
                        <div class="size-2 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                    </div>
                </div>


                <div class="grow pt-0.5 pb-8">
                    <h3 class="flex gap-x-1.5 font-semibold text-gray-800 dark:text-white">
                        Marked "Install Charts" completed
                    </h3>
                    <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">
                        Finally! You can check it out here.
                    </p>
                    <button type="button" class="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                        <img class="flex-shrink-0 size-4 rounded-full" src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=3&w=320&h=320&q=80" alt="Image Description" />
                        James Collins
                    </button>
                </div>

            </div>

            <div class="flex gap-x-3">
                <div class="w-28 text-end">
                    <span class="text-base text-gray-500 dark:text-gray-400">12:05PM</span>
                </div>

                <div class="min-h-24 relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
                    <div class="relative z-10 size-7 flex justify-center items-center">
                        <div class="size-2 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                    </div>
                </div>

                <div class="grow pt-0.5 pb-8">
                    <h3 class="flex gap-x-1.5 font-semibold text-gray-800 dark:text-white">
                        Take a break ⛳️
                    </h3>
                    <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">
                        Just chill for now... 😉
                    </p>
                </div>
            </div> */}

            <div class="flex gap-x-3">

                <div class="w-28 text-end">
                    <span class="text-base text-gray-500 dark:text-gray-400"></span>
                </div>

                {isMoreAvailable && (
                <div class="ps-[7px] flex gap-x-3">
                    <button type="button" onClick={handleShowMore} 
                    class="hs-collapse-toggle hs-collapse-open:hidden text-start inline-flex items-center gap-x-1 text-sm text-blue-600 font-medium decoration-2 hover:underline dark:text-blue-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" id="hs-timeline-collapse-content" data-hs-collapse="#hs-timeline-collapse">
                        <svg class="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6" /></svg>
                        Show older
                    </button>
                </div>)}
            </div>
            {/* <!-- End Item --> */}
            {/* // <!-- End Timeline --> */}
        </div>
    )
}

export default Timeline