import React, { useEffect, useState } from "react";
import { faker } from "@faker-js/faker";
import Dropzone from "./Dropzone";
import { GLOBALTYPES } from "../../redux/actions/globalTypes";
import { createAction } from "../../redux/actions/caseAction";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

const Editor_Show = ({ file }) => {
  const theme = "bubble";
  //theme={"bubble"}
  const { quill, quillRef } = useQuill({ theme });

  console.log("file_EEEEEE", file)

  useEffect(() => {
    if (quill) {
      // quill.clipboard.dangerouslyPasteHTML('<h1> TiTle</h1><ol><li>asdfasdf oreder list</li><li>sadfaf</li><li>asdfas</li></ol><p>asdfjhaslkjdfhajkshdfkjh pargtaph</p><h2> Responcebility</h2><ul><li>unorderlist</li><li>sdfsd</li><li>asdfsa</li></ul><h3> color</h3><p><strong style="background-color: rgb(0, 138, 0);">asdfasfasdfasdfsdfasdfasdfsfadf</strong></p><p><span style="color: rgb(178, 178, 0);">asdfsdfasddaasdfasdfasdfasdfadf</span></p><p><u style="color: rgb(178, 178, 0);">aasdfasdfasfadfsfaasfdfasfasfd</u></p>');
      quill.clipboard.dangerouslyPasteHTML(file);
    }
  }, [quill]);

  return (
    <div>
      <div ref={quillRef} />
    </div>
  );
};

export default Editor_Show;
