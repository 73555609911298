import { MdOutlineCancel } from "react-icons/md";
import { Button } from ".";
import { chatData } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { GLOBALTYPES } from '../redux/actions/globalTypes';
import { admincreateUser} from '../redux/actions/userAction';
import pica from "pica";

const Add_User = ({ user }) => {
  const { currentColor, setIsClicked, initialState  } = useStateContext();

  console.log("id user prpo ", user);

  

  const { auth, alert } = useSelector((state) => state);
  const dispatch = useDispatch();

  const initialState1 = {
    full_name: user.full_name || "",
    username: user.username || "",
    email: user.email || "",
    password: "",
    cf_password: "",
  };
  const [userData, setUserData] = useState(initialState1);
  const { full_name, username, email, password, cf_password } = userData;

  const [typePass, setTypePass] = useState(false);
  const [typeCfPass, setTypeCfPass] = useState(false);

 


  useEffect(() => {
    if(alert.success){
    setIsClicked(initialState)
    }
  }, [alert]);

  
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
 

 
  const handleSubmit = (e) => {
    e.preventDefault();

      dispatch(admincreateUser({ userData, auth }));
  };

  return (
    <div className=" absolute bg-half-transparent w-full h-full flex justify-center  nav-item top-0 right-0 ">
      <div className="fixed md:overflow-hidden overflow-auto md:hover:overflow-auto top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-max h-max max-h-screen">
        <div className="flex justify-between items-center">
          <div className="flex gap-3">
            <p className="font-semibold text-lg dark:text-gray-200">
              {user ? "Edit User" : "Add User"}
            </p>
          </div>
          <Button
            icon={<MdOutlineCancel />}
            color="rgb(153, 171, 180)"
            bgHoverColor="light-gray"
            size="2xl"
            borderRadius="50%"
          />
        </div>
        <div className="mt-5 ">
          

          <form className="space-y-4 md:space-y-6 min-w-72 mx-auto" onSubmit={handleSubmit}>
              <div>
                <label
                  for="fullname"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  name="full_name"
                  id="full_name"
                  onChange={handleChangeInput}
                  value={full_name}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=" "
                  required=""
                  style={{
                    background: `${alert.full_name ? "#fd2d6a14" : ""}`,
                  }}
                ></input>
                <small className="text-sm font-medium text-red-500">
                  {alert.full_name ? alert.full_name : ""}
                </small>
              </div>
              <div>
                <label
                  for="username"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  EEU ID
                </label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  onChange={handleChangeInput}
                  value={username}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=" "
                  required=""
                  style={{ background: `${alert.username ? "#fd2d6a14" : ""}` }}
                ></input>
                <small className="text-sm font-medium text-red-500">
                  {alert.username ? alert.username : ""}
                </small>
              </div>
              <div>
                <label
                  for="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleChangeInput}
                  value={email}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@company.com"
                  required=""
                  style={{ background: `${alert.email ? "#fd2d6a14" : ""}` }}
                ></input>
                <small className="text-sm font-medium text-red-500">
                  {alert.email ? alert.email : ""}
                </small>
              </div>
              <div>
                <label
                  for="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={handleChangeInput}
                  value={password}
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                  style={{ background: `${alert.password ? "#fd2d6a14" : ""}` }}
                ></input>
                <small className="text-sm font-medium text-red-500">
                  {alert.password ? alert.password : ""}
                </small>
              </div>
              <div>
                <label
                  for="confirm-password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Confirm password
                </label>
                <input
                  type="password"
                  name="cf_password"
                  id="cf_password"
                  onChange={handleChangeInput}
                  value={cf_password}
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                  style={{
                    background: `${alert.cf_password ? "#fd2d6a14" : ""}`,
                  }}
                ></input>
                <small className="text-sm font-medium text-red-500">
                  {alert.cf_password ? alert.cf_password : ""}
                </small>
              </div>
              <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
              
            </form>
        </div>
      </div>
    </div>
  );
};

export default Add_User;
