import { GLOBALTYPES } from './globalTypes';
import { imageUpload, deletefile } from '../../utils/imageUpload';
import {
  postDataAPI,
  getDataAPI,
  patchDataAPI,
  deleteDataAPI,
} from '../../utils/fetchData';
// import { createNotify, removeNotify } from './notifyAction';

export const CASE_TYPES = {
  CREATE_CASE: 'CREATE_CASE',
  REPLAY_CASE: 'REPLAY_CASE',
  LOADING_CASE: 'LOADING_CASE',
  GET_CASES: 'GET_CASES',
  UPDATE_CASE: 'UPDATE_CASE',
  GET_CASE: 'GET_CASE',
  DELETE_CASE: 'DELETE_CASE',
  CREATE_ACTIONCASE: 'CREATE_ACTIONCASE',
  CREATE_REPLY: 'CREATE_REPLY',
  GET_DASHBOARD: 'GET_DASHBOARD',
  GET_TABLEDASHBOARD: 'GET_TABLEDASHBOARD',
  DELETE_ACTION: 'DELETE_ACTION',
  GET_PROCESSCOMPLETEDBYID: 'GET_PROCESSCOMPLETEDBYID',
  GET_TABLECOMPLETED: 'GET_TABLECOMPLETED',
};

export const replayCase =
  ({ caseData, auth }) =>
  async (dispatch) => {
    // let media = [];
    //console.log("daaaaaaaaaaaaaataaaaaaaa", caseData )
    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
      // if (images.length > 0) media = await imageUpload(images);

      const res = await postDataAPI(
        'devices',
        caseData ,
        auth.token
      );

      dispatch({
        type: CASE_TYPES.CREATE_CASE,
        payload: res.data,
      });

      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { success: 'Successfully replayed!' },
      });

      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });

    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };

export const createDevice =
  ({ caseData, auth }) =>
  async (dispatch) => {
    // let media = [];
    //console.log("daaaaaaaaaaaaaataaaaaaaa", caseData )
    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
      // if (images.length > 0) media = await imageUpload(images);

      const res = await postDataAPI(
        'devices',
        caseData ,
        auth.token
      );

      dispatch({
        type: CASE_TYPES.CREATE_CASE,
        payload: res.data,
      });

      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { success: 'Successfully created!' },
      });

      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });

    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };

export const getCases = (token) => async (dispatch) => {
  try {
    dispatch({ type: CASE_TYPES.LOADING_CASE, payload: true });
    const res = await getDataAPI(`mail/eeuCases/`, token);
    // const res = await getDataAPI(`get_devices`, token);
    //console.log('get res.data', res.data);

    dispatch({
      type: CASE_TYPES.GET_CASES,
      payload: res.data,
    });

    dispatch({ type: CASE_TYPES.LOADING_CASE, payload: false });
  } catch (err) {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: { error: err.response.data.msg },
    });
  }
};


export const getCase_byid = (id,token) => async (dispatch) => {

  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true }});
    const res = await getDataAPI(`mail/getCase_byid/${id}`, token);
    // const res = await getDataAPI(`get_devices`, token);
    console.log('get res.data getCase_byid', res.data);

    dispatch({
      type: CASE_TYPES.GET_CASE,
      payload: res.data
    });

    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
  } catch (err) {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: { error: err.response.data.msg },
    });
  }

};

export const updateDevice =
  ({ id, caseData, auth }) =>
  async (dispatch) => {

    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

      const res = await patchDataAPI(
        `device/${id}`,
        caseData,
        auth.token
      );
      //console.log("update device",res.data)

      dispatch({ type: CASE_TYPES.UPDATE_CASE, payload:  res.data });

      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { success: 'Update Successfully!' },
      });



      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });

    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err },
      });
    }
  };

export const likeDevice =
  ({ post, auth, socket }) =>
  async (dispatch) => {
    const newDevice = { ...post, likes: [...post.likes, auth.user] };
    dispatch({ type: CASE_TYPES.UPDATE_CASE, payload: newDevice });

    socket.emit('likeDevice', newDevice);

    try {
      await patchDataAPI(`post/${post.id}/like`, null, auth.token);

      // Notify
      // const msg = {
      //   id: auth.user.id,
      //   text: 'like your post.',
      //   recipients: [post.user.id],
      //   url: `/post/${post.id}`,
      //   content: post.content,
      //   image: post.images[0].url,
      // };

      // dispatch(createNotify({ msg, auth, socket }));
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };

export const unLikeDevice =
  ({ post, auth, socket }) =>
  async (dispatch) => {
    const newDevice = {
      ...post,
      likes: post.likes.filter((like) => like.id !== auth.user.id),
    };
    dispatch({ type: CASE_TYPES.UPDATE_CASE, payload: newDevice });

    socket.emit('unLikeDevice', newDevice);

    try {
      await patchDataAPI(`post/${post.id}/unlike`, null, auth.token);

      // Notify
      // const msg = {
      //   id: auth.user.id,
      //   text: 'like your post.',
      //   recipients: [post.user.id],
      //   url: `/post/${post.id}`,
      // };
      // dispatch(removeNotify({ msg, auth, socket }));
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };

export const getDevice =
  ({ detailDevice, id, auth }) =>
  async (dispatch) => {
    if (detailDevice.every((post) => post.id !== id)) {
      try {
        const res = await getDataAPI(`post/${id}`, auth.token);
        dispatch({ type: CASE_TYPES.GET_CASE, payload: res.data.post });
      } catch (err) {
        dispatch({
          type: GLOBALTYPES.ALERT,
          payload: { error: err.response.data.msg },
        });
      }
    }
  };

export const deleteCase =
  ( id, auth) =>
  async (dispatch) => {
    // dispatch({ type: CASE_TYPES.DELETE_CASE, payload: id });
console.log("delete123id", id)
    try {
      const res = await deleteDataAPI(`mail/deleteCase/${id}`, auth.token);

      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { success: res.data.message },
      });

      dispatch({
        type: CASE_TYPES.DELETE_CASE,
        payload: {id},
      });

      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
      
    } catch (err) {
      //console.log(err)
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };

export const saveDevice =
  ({ post, auth }) =>
  async (dispatch) => {
    const newUser = { ...auth.user, saved: [...auth.user.saved, post.id] };
    dispatch({ type: GLOBALTYPES.AUTH, payload: { ...auth, user: newUser } });

    try {
      await patchDataAPI(`saveDevice/${post.id}`, null, auth.token);
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };

export const unSaveDevice =
  ({ post, auth }) =>
  async (dispatch) => {
    const newUser = {
      ...auth.user,
      saved: auth.user.saved.filter((id) => id !== post.id),
    };
    dispatch({ type: GLOBALTYPES.AUTH, payload: { ...auth, user: newUser } });

    try {
      await patchDataAPI(`unSaveDevice/${post.id}`, null, auth.token);
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };




export const createAction =
  ({ actiondata, auth }) =>
  async (dispatch) => {
    // let media = [];
    // console.log("daaaaaaaaaaaaaataaaaaaaa", actionData )
    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
      // if (images.length > 0) media = await imageUpload(images);

      const res = await postDataAPI(
        'mail/eeuaction/',
        actiondata ,
        auth.token
      );

      // console.log("res action data daaaaaaaaaaaaaataaaaaaaa", res.data )
      dispatch({
        type: CASE_TYPES.CREATE_ACTIONCASE,
        payload: res.data,
      });

      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { success: 'Successfully submited!' },
      });

      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });

    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data },
      });
    }
  };


  export const deleteAction =
  ( id, auth) =>
  async (dispatch) => {
    // dispatch({ type: CASE_TYPES.DELETE_CASE, payload: id });
// console.log("delete123id", id)
    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

      const res = await deleteDataAPI(`mail/deleteAction/${id}`, auth.token);

      // console.log("delete123id Action", res)
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { success: "deleted successfully" },
      });

      dispatch({
        type: CASE_TYPES.DELETE_ACTION,
        payload: {id},
      });

      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
      
    } catch (err) {
      //console.log(err)
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };

  export const createProcessCompleted = ({ProcessCompletedData, auth }) => async (dispatch) => {
      // let media = [];
      console.log("ProcessCompleted  ppp", ProcessCompletedData )
      try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
        // if (images.length > 0) media = await imageUpload(images);
  
        const res = await postDataAPI(
          'mail/processCompleted/',
          ProcessCompletedData ,
          auth.token
        );
  
        // // console.log("reply res daaaaaaaaaaaaaataaaaaaaa", res.data )
        // dispatch({
        //   type: CASE_TYPES.CREATE_REPLY,
        //   payload: res.data,
        // });
  
        dispatch({
          type: GLOBALTYPES.ALERT,
          payload: { success:  res.data.msg },
        });
  
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
  
      } catch (err) {
        dispatch({
          type: GLOBALTYPES.ALERT,
          payload: { error: err.response.data },
        });
      }
    };

    export const getProcessCompleted_byid = (complain,token) => async (dispatch) => {

      try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true }});
        const res = await getDataAPI(`mail/getProcessCompleted_byid/${complain}`, token);
        // const res = await getDataAPI(`get_devices`, token);
        console.log('get res.data getProcessCompleted_byid', res.data);
    
        dispatch({
          type: CASE_TYPES.GET_PROCESSCOMPLETEDBYID,
          payload: res.data
        });
    
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
      } catch (err) {
        dispatch({
          type: GLOBALTYPES.ALERT,
          payload: { error: err.response.data.msg },
        });
      }

    }

    export const getTableProcessCompleted = (startDate, endDate,token) => async (dispatch) => {

      try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true }});
        const res = await getDataAPI(`mail/getProcessCompleted/?startDate=${startDate}&endDate=${endDate}`, token);
        // console.log('getProcessCompleted All res.data', res.data);
    
        dispatch({
          type: CASE_TYPES.GET_TABLECOMPLETED,
          payload: res.data
        });
    
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
      } catch (err) {
        dispatch({
          type: GLOBALTYPES.ALERT,
          payload: { error: err.response.data.msg },
        });
      }
    
    };

  export const createReply =
  ({ replydata, auth }) =>
  async (dispatch) => {
    // let media = [];
    console.log("replydata  inter", replydata, auth )
    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
      // if (images.length > 0) media = await imageUpload(images);

      const res = await postDataAPI(
        'mail/eeureply/',
        replydata ,
        auth.token
      );

      // console.log("reply res daaaaaaaaaaaaaataaaaaaaa", res.data )
      dispatch({
        type: CASE_TYPES.CREATE_REPLY,
        payload: res.data,
      });

      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { success: 'Successfully Replied!' },
      });

      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });

    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data },
      });
    }
  };


  export const caseSearch = (query,token) => async (dispatch) => {
    console.log("query  inter",query,token )
    try {
      dispatch({ type: CASE_TYPES.LOADING_CASE, payload: true });
      const res = await getDataAPI(
        `mail/caseSearch/?query=${query}`,
        token
      );
  
      dispatch({
        type: CASE_TYPES.GET_CASES,
        payload: res.data,
      });
  
      dispatch({ type: CASE_TYPES.LOADING_CASE, payload: false });
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };


  
  export const dateSearch = (formattedDate,token) => async (dispatch) => {
    console.log("formattedDate inter",formattedDate,token )
    try {
      dispatch({ type: CASE_TYPES.LOADING_CASE, payload: true });
      const res = await getDataAPI(
        `mail/caseSearch/?date=${formattedDate}`,
        token
      );
  
      dispatch({
        type: CASE_TYPES.GET_CASES,
        payload: res.data,
      });
  
      dispatch({ type: CASE_TYPES.LOADING_CASE, payload: false });
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  }


  export const getDashboard = (startDate, endDate,token) => async (dispatch) => {

    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true }});
      const res = await getDataAPI(`mail/getDashboard/?startDate=${startDate}&endDate=${endDate}`, token);
      console.log('dashboard res.data', res.data);
  
      dispatch({
        type: CASE_TYPES.GET_DASHBOARD,
        payload: res.data
      });
  
      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  
  };

  export const getTableDashboard = (itemstring, startDate, endDate,token) => async (dispatch) => {

    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true }});
      const res = await getDataAPI(`mail/getTabelDashboard/?itemString=${itemstring}&startDate=${startDate}&endDate=${endDate}`, token);
      console.log('dashboard res.data', res.data);
  
      dispatch({
        type: CASE_TYPES.GET_TABLEDASHBOARD,
        payload: res.data
      });
  
      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  
  };



  
