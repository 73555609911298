import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import { pdfjs } from "react-pdf";
import { Link } from "react-router-dom";
import { MdOutlineDeleteForever } from "react-icons/md";
import { deleteAction } from "../../redux/actions/caseAction";

import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.bubble.css';
import Editor_Show from "../Editor/Editor_Show";

const Case_File = () => {
  const [casefile, setCasefile] = useState([]);

  const {auth, cases } = useSelector((state) => state);
  const dispatch = useDispatch();

  const theme = 'bubble';
  //theme={"bubble"}
    const { quill, quillRef } = useQuill({theme});


  useEffect(() => {
    function updateFiles() {
      const filteredData = cases?.action?.filter(
        (item) => item.attachments.length != 0 || item.editor !== null
      );
      setCasefile(filteredData);
    }

    updateFiles();
  }, [cases]);

  function getFilenameFromUrl(url) {
    try {
      const parsedUrl = new URL(url);
      const pathname = parsedUrl.pathname;
      const pathSegments = pathname.split("/");
      const filename = pathSegments.pop();
      return filename || null;
    } catch (error) {
      console.error("Invalid URL:", error);
      return null;
    }
  }

  const openInNewTab = (componentUrl) => {
    const newWindow = window.open(componentUrl, "_blank");
    if (newWindow) {
      // Check if new window was successfully opened
      newWindow.focus(); // Focus the new window for better user experience
    }
  };

  const covertdate = (date) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    // Parse the timestamp string into a Date object
    const date1 = new Date(date);

    // Use toLocaleDateString() on the Date object
    return date1.toLocaleDateString("en-US", options);
  };

  return (
    <div className="mt-10">
      <div className="text-xl font-body mb-3 ml-16">Case File</div>
      {casefile?.length > 0 && ( // Check for casefile existence and length
        <ul className="ml-10">
          {casefile?.map((file) => (
            <div className="MainSection border  dark:border-white">
              <div className="flex justify-between items-center w-full">
              <div>{file.user.username}</div>
              <div key={file.id || Math.random()}>
                {covertdate(file.created_at)}
              </div>
              {(auth.user?.role == 'admin' || auth.user?.username == file.user.username) && <div onClick={() => {
                if (window.confirm(`Are you sure you want to delete the file?`)) {
                   dispatch(deleteAction(file.id , auth)); 
                }
              }}>
                <MdOutlineDeleteForever style={{ fontSize: '25px', color: 'red' }} />
              </div>}
              </div>
              <hr></hr>
              {file.editor && <Editor_Show  file={file.editor}/>}
              
              {/* {file.editor  && <div className="mt-4"> */}
               {/* {parse(file.editor )} */}
               {/* <div/>
                       <div ref={quillRef} >
                    </div>
              </div>} */}
              <li
                key={file.id || Math.random()}
                className="attachment-list flex flex-wrap items-center justify-between m-2 gap-1"
              >
                {" "}
                {/* Add a unique key */}
                {file?.attachments?.map((item) => {
                  // <span class="text-base text-gray-500 dark:text-gray-400">covertdate(data.created_at)</span>
                  const isImage =
                    item.file.endsWith(".png") ||
                    item.file.endsWith(".PNG") ||
                    item.file.endsWith(".jpg") ||
                    item.file.endsWith(".jpeg") ||
                    item.file.endsWith(".gif");
                  const isVideo =
                    item.file?.endsWith(".mp4") || item.file.endsWith(".webm");
                  const pdf = item.file.endsWith(".pdf");

                  if (isImage) {
                    return (
                      <li key={item.id} className="w-1/4">
                        {" "}
                        {/* Set width to 1/3 for each item */}
                        <a href={item.file}>
                          <img
                            src={item.file}
                            alt="Image"
                            className="object-cover rounded-md"
                          />
                        </a>
                        <div className="">{getFilenameFromUrl(item?.file)}</div>
                      </li>
                    );
                  } else if (isVideo) {
                    return (
                      <li key={item.id} className="w-1/3">
                        {/* Set width to 1/3 for each item */}
                        <a href={item.file}>
                          <video
                            src={item.file}
                            autoPlay
                            muted
                            className="h-full w-full object-cover rounded-md"
                          />
                        </a>
                        <div className="">{getFilenameFromUrl(item.file)}</div>
                      </li>
                    );
                  } else if (pdf) {
                    return (
                      <li key={item.id} className="w-1/3">
                        {" "}
                        {/* Set width to 1/3 for each item */}
                        <button onClick={() => openInNewTab(item.file)}>
                          <FontAwesomeIcon
                            icon={faFilePdf}
                            size="3x"
                            className="fa-fw "
                          />
                        </button>
                        <div className="">{getFilenameFromUrl(item.file)}</div>
                      </li>
                    );
                  } else {
                    return (
                      <li key={item.id} className="w-1/3">
                        {" "}
                        {/* Set width to 1/3 for each item */}
                        <a href={item.file}>
                          {/* <span>Unknown attachment ({item.split(".").pop()})</span> */}
                          <FontAwesomeIcon
                            icon={faFile}
                            size="3x"
                            className="fa-fw "
                          />
                        </a>
                        <div className="">{getFilenameFromUrl(item.file)}</div>
                      </li>
                    );
                  }
                })}

              
                  
                 
                  
                




              </li>
            
            </div>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Case_File;
