import { GLOBALTYPES } from './globalTypes'
import { postDataAPI, patchDataAPI, getDataAPI, deleteDataAPI } from '../../utils/fetchData'
import valid from '../../utils/valid'
import axios from 'axios'
import {BASE_URL}  from '../../utils/config'


export const USER_TYPES = {
  CREATE_USER: 'CREATE_USER',
  LOADING_USER: 'LOADING_USER',
  GET_USER: 'GET_USER',
  UPDATE_USER: 'UPDATE_USER',
  DELETE_USER: 'DELETE_USER',
};


export const getUsers = (token) => async (dispatch) => {
    try {
        dispatch({type: GLOBALTYPES.ALERT, payload: {loading: true}})
      const res = await getDataAPI('get_users', token);
      // const res = await getDataAPI(`get_posts`, token);
      console.log('get res.data', res.data);
  
      dispatch({
        type: USER_TYPES.GET_USER, 
        payload: res.data
      });
  
      dispatch({type: GLOBALTYPES.ALERT, payload: {loading: false}})
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };

export const admincreateUser = ({ userData, auth }) => async (dispatch) => {
    const check = valid(userData)
    if(check.errLength > 0)
    return dispatch({type: GLOBALTYPES.ALERT, payload: check.errMsg})

    try {
        dispatch({type: GLOBALTYPES.ALERT, payload: {loading: true}})

        const res = await postDataAPI('create_user', userData , auth.token)
        console.log("ressssssssssss", res.data)
        dispatch({ 
            type: USER_TYPES.CREATE_USER, 
            payload: userData
        })


        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                success: res.data.msg
            } 
        })
        dispatch({type: GLOBALTYPES.ALERT, payload: {loading: false}})
    } catch (err) {
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        })
    }
}

export const updateUser =
  ({ id ,userData, auth}) =>
  async (dispatch) => {

    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

      const res = await patchDataAPI(
        `updateUser/${id}`,
        userData,
        auth.token
      );

    //   dispatch({ type: GLOBALTYPES.AUTH, payload: {user: res.data} });

      dispatch({ type: GLOBALTYPES.ALERT, payload: { success: res.data.msg } });
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data },
      });
    }
  };


  export const deleteUser = ({ id, auth}) => async (dispatch) => {
    
    try {
      const res = await deleteDataAPI(`delete_user/${id}`, auth.token);

      dispatch({
        type: USER_TYPES.DELETE_USER,
        payload: id,
      });

    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };

  
  export const admin_changepassword =
  ({id_user, newPassword, auth}) =>
  async (dispatch) => {

    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
      const body= {id_user, newPassword}

      const res = await postDataAPI(
        `admin_changepassword/`,
        body,
        auth.token
      );

      dispatch({ type: GLOBALTYPES.ALERT, payload: { success: res.data.msg } });
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };