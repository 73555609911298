import React, { useRef, useState, useEffect } from 'react'
import { faker } from '@faker-js/faker';

// import { getCase_byid } from '../redux/actions/caseAction';
import { createAction, createReply } from '../../redux/actions/caseAction';
// import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
// import { useStateContext } from '../contexts/ContextProvider';
import { GLOBALTYPES } from '../../redux/actions/globalTypes';
import Reply from './Reply';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import "./style.css"
import { pdfjs } from "react-pdf";
import { Link } from 'react-router-dom';
import ProcessCompletedPopup from './ProcessCompletedPopup';



pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();



const Case_Detail = ({ data, lastaction, registeredUsers }) => {

    // console.log("lastaction lastaction lastaction", lastaction)
    const [takeActionOpen, setTakeActionOpen] = useState(false)
    const [reporterOpen, setReporterOpen] = useState(false)
    const [replyOpen, setReplyOpen] = useState(false)

    const [replySubject, setReplySubject] = useState(data?.subject)
    const [replyBody, setReplyBody] = useState("")

    const [ProcessCompletedOpen, setProcessCompletedOpen] = useState(false)

    

    const initialState1 = {
        category: lastaction?.category || '',
        status: lastaction?.status || '',
        priority: lastaction?.priority || '',
        assign_to: lastaction?.assign_to?.username || '',
        remark: '',
    };

    const [actionData1, setActionData1] = useState(initialState1);
    const { category, status, priority, assign_to, remark } = actionData1;

    const { auth, cases } = useSelector((state) => state);
    const dispatch = useDispatch();

    useEffect(() => {
        setActionData1({ category: lastaction?.category || '',
            status: lastaction?.status || '',
            priority: lastaction?.priority || '',
            assign_to: lastaction?.assign_to?.username || '',
            remark: '' });

        }, [lastaction]);


    const covertdate = (date) => {
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };

        // Parse the timestamp string into a Date object
        const date1 = new Date(date);

        // Use toLocaleDateString() on the Date object
        return date1.toLocaleDateString('en-US', options);
    };

    // const device_fingerptint = device?.osBuildFingerprint;
    // const device_fingerptint1 = device_fingerptint.split(":");

    function getFilenameFromUrl(url) {
        const parsedUrl = new URL(url);
        const pathname = parsedUrl.pathname;
        const pathSegments = pathname.split('/');
        const filename = pathSegments.pop();
        return filename || null;
    }

    const takeAction = () => {

        const handleChangeInput = (e) => {
            const { name, value } = e.target;
            setActionData1({ ...actionData1, [name]: value });
        };

        const SubmitAction = (e) => {
            e.preventDefault();
            if (!remark)
                return dispatch({
                    type: GLOBALTYPES.ALERT,
                    payload: { error: 'Please add a Remark.' },
                });

            const actiondata = {}
            if (lastaction?.category !== category) { actiondata.category = category }
            if (lastaction?.status !== status) { actiondata.status = status }
            if (lastaction?.priority !== priority) { actiondata.priority = priority }
            if (lastaction?.assign_to?.username !== assign_to) { actiondata.assign_to = assign_to }

            actiondata.remark = remark;
            actiondata.complain = data?.id;
            actiondata.user = auth.user.id;


            dispatch(createAction({ actiondata, auth }));

            setActionData1({ ...actionData1, remark: '' });
            setTakeActionOpen(!takeActionOpen)

        };


        return (
            // <!-- Main modal -->
            <div id="crud-modal" tabindex="-1" aria-hidden="true" class="bg-half-transparent overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div class="relative p-4 w-full max-w-xl  max-h-full">
                    {/* <!-- Modal content --> */}
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 ">
                        {/* <!-- Modal header --> */}
                        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                                Change Status
                            </h3>
                            <button onClick={() => setTakeActionOpen(!takeActionOpen)}
                                type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal">
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                        {/* <!-- Modal body --> */}
                        <form class="p-4 md:p-5">
                            <div class="grid gap-4 mb-4 mx-3  grid-cols-2">

                                {/* <div class="col-span-2">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                        <input type="text" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Type product name" required=""/>
                    </div> */}
                                {/* <div class="col-span-2 sm:col-span-1">
                        <label for="price" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Price</label>
                        <input type="number" name="price" id="price" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="$2999" required=""/>
                    </div> */}
                                <div class="col-span-2 sm:col-span-1">
                                    <label for="status" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status</label>
                                    <select id="status"
                                        name='status'
                                        onChange={handleChangeInput}
                                        value={status}
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                        <option selected="">{status ? status : "Select Status"}</option>
                                        <option value="Pending">Pending</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="Canceled">Cancel</option>
                                        <option value="Process Completed">Process Completed</option>
                                        {/* <option value="Closed">Closed</option>
                                        <option value="Terminated">Terminated</option> */}

                                    </select>
                                </div>
                                <div class="col-span-2 sm:col-span-1">
                                    <label for="priority" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Priority</label>
                                    <select id="priority"
                                        name='priority'
                                        onChange={handleChangeInput}
                                        value={priority}
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                        <option selected="">{priority ? priority : "Select Priority"}</option>
                                        <option value="Big">Big</option>
                                        <option value="High">High</option>
                                        <option value="Normal">Normal</option>
                                        <option value="Small">Small</option>
                                    </select>
                                </div>
                                <div class="col-span-2 sm:col-span-1">
                                    <label for="category"
                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category</label>
                                    <select id="category"
                                        name='category'
                                        onChange={handleChangeInput}
                                        value={category}
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                        <option selected="">{category ? category : "Select category"}</option>
                                        {/* <option value="Payment">Payment</option> */}
                                        {/* <option value="Power">Power</option>
                                        <option value="Privilege">Privilege</option> */}
                                        {/* <option value="Preference">Preference</option> */}
                                        <option value="Bribery">Bribery</option>
                                        <option value="Theft">Theft</option>
                                        <option value="Billing fraud">Billing fraud</option>
                                        <option value="Harassment">Harassment</option>
                                    </select>
                                </div>

                                <div class="col-span-2 sm:col-span-1">
                                    <label for="assign_to" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Assign To</label>
                                    <select id="assign_to"
                                        name='assign_to'
                                        onChange={handleChangeInput}
                                        value={assign_to}
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                        <option selected="">{assign_to }</option>
                                        {registeredUsers?.map(option => (
                                            (assign_to != option.username) && <option key={option.username} value={option.username}>{option.username}</option>
                                        ))}
                                    </select>
                                </div>

                                <div class="col-span-2">
                                    <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Remark</label>
                                    <textarea id="description" rows="4"
                                        name='remark'
                                        onChange={handleChangeInput}
                                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write Remark here"></textarea>
                                </div>
                            </div>
                            <div className='flex justify-end'>
                            <Link to={`/Editor/${data?.id}`} >
                                <button
                    // onClick={() => setReplyOpen(!replyOpen)}
                    type="button"
                    className="mr-10 inline-block rounded-lg bg-sky-500 px-6 pb-2 pt-2.5 text-sm font-medium leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                >
                    Editor
                </button>
                </Link>

                           
                                <button type="submit"
                                    onClick={SubmitAction}
                                    class="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    {/* <svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg> */}
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }



    // const replyPopup = () => {

    //     const SubmitReply = (e) => {
    //         e.preventDefault();
    //         if (!replyBody)
    //             return dispatch({
    //                 type: GLOBALTYPES.ALERT,
    //                 payload: { error: 'Please add a Body.' },
    //             });

    //         const replydata = {
    //             subject : replySubject,
    //             body : replyBody,
    //             recipients: data?.sender,
    //             complain: data?.id,
    //             sender: "eeu",
    //         }

    //         console.log("replydata", replydata)

    //         dispatch(createReply({replydata, auth }));

    //         setReplyOpen(!replyOpen)

    //     };



    //     return (
    //         // <!-- Main modal -->
    //         <div id="crud-modal" tabindex="-1" aria-hidden="true" class="bg-half-transparent overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    //             <div class="relative p-4 w-full max-w-xl  max-h-full">
    //                 {/* <!-- Modal content --> */}
    //                 <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 ">
    //                     {/* <!-- Modal header --> */}
    //                     <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
    //                         <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
    //                             Take Action
    //                         </h3>
    //                         <button onClick={() => setReplyOpen(!replyOpen)}
    //                             type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal">
    //                             <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    //                                 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
    //                             </svg>
    //                             <span class="sr-only">Close modal</span>
    //                         </button>
    //                     </div>
    //                     {/* <!-- Modal body --> */}
    //                     <form class="p-4 md:p-5">
    //                         <div class="grid gap-4 mb-4 mx-3  grid-cols-2">

    //                             <div class="col-span-2">
    //                                 <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Subject</label>
    //                                 <input type="text" name="subject" id="subject"  
    //                                            onChange={(e) => setReplySubject(e.target.value)}
    //                                            value={replySubject}
    //                                 class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Type product name" required="" />
    //                             </div>
    //                             {/* <div class="col-span-2 sm:col-span-1">
    //                     <label for="price" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Price</label>
    //                     <input type="number" name="price" id="price" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="$2999" required=""/>
    //                 </div> */}

    //                             <div class="col-span-2">
    //                                 <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Body</label>
    //                                 <textarea id="description" rows="5" name='body'
    //                                     onChange={(e) => setReplyBody(e.target.value)}
    //                                     value={replyBody}
    //                                     class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write here"></textarea>
    //                             </div>
    //                         </div>
    //                         <div className='flex justify-end'>
    //                             <button type="submit"
    //                                 onClick={SubmitReply}
    //                                 class="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
    //                                 {/* <svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg> */}
    //                                 Submit
    //                             </button>
    //                         </div>
    //                     </form>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }



    const reporter_Detail = () => {
        return (
            <div id="crud-modal" tabindex="-1" aria-hidden="true" class="bg-half-transparent overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div class="relative p-4 w-full max-w-xl ">
                    {/* <!-- Modal content --> */}
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 ">
                        {/* <!-- Modal header --> */}
                        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                            Whistleblower Detail
                            </h3>
                            <button onClick={() => setReporterOpen(!reporterOpen)}
                                type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal">
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="mx-10 my-10 flex justify-center">
                            <table size="auto">
                                <tbody>
                                    <tr className='border-t border-b '>
                                        <td className="px-4 py-2">Name :</td>
                                        <td className="text-left">
                                            <a href="#" className="">{data?.full_name}</a>
                                        </td>
                                    </tr>
                                    <tr className='border-t border-b '>
                                        <td className="px-4 py-2">Phone :</td>
                                        <td className="text-left">
                                            <a href="#" className="">{data?.phone_number}</a>
                                        </td>
                                    </tr>
                                    <tr className='border-t border-b '>
                                        <td className="px-4 py-2">Email :</td>
                                        <td className="text-left">
                                            <a href="#" className="">{data?.email}</a>
                                        </td>
                                    </tr>

                                    <tr className='border-t border-b '>
                                        <td className="px-4 py-2 mb-20">Address :</td>
                                        <td className="text-left">
                                            <a href="#" className="">{data?.address}</a>
                                        </td>
                                    </tr>

                                    {/* <tr className='border-t border-b '>
                                        <td className="px-4 py-2">Device Fingerprint :</td>
                                        <td className="">
                                            <div>{device_fingerptint1[0]}</div>
                                            <div>{device_fingerptint1[1]}</div>
                                        </td>
                                    </tr>

                                    <tr className='border-t border-b '>
                                        <td className="px-4 py-2 mb-20">Device Name :</td>
                                        <td className="text-left">
                                            <a href="#" className="">{device?.deviceName}</a>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                        <div class="border-b ml-5 w-1/2 "></div>
                    </div>
                </div>
            </div>
        )
    }

    const Printdoc = () => {
        window.print();
    }

    const openInNewTab = (componentUrl) => {
        const newWindow = window.open(componentUrl, '_blank');
        if (newWindow) { // Check if new window was successfully opened
          newWindow.focus(); // Focus the new window for better user experience
        }
      };

    return (
        <div className="mx-20 my-10 dark:text-white">
            <div className="text-2xl font-body mb-10">
                Case Details
            </div>
           

            <table size="table-auto">
                <tbody>
                    <tr className='border-t border-b '>
                        <td className="px-4 py-2">Case Number</td>
                        <td className="text-left">
                            <a href="#" className="">{data?.id}</a>
                        </td>
                    </tr>
                    <tr className='border-b'>
                        <td className="px-4 py-2">Incident Date</td>
                        <td className="text-left">
                            {data?.incident_happend_Date}
                        </td>
                    </tr>

                    <tr className='border-b'>
                        <td className="px-4 py-2">location</td>
                        <td className="">
                            {data?.location}
                        </td>
                    </tr>

                    <tr className='border-b'>
                        <td className="px-4 py-2">responsible EEU center</td>
                        <td className="">
                            {data?.eeu_office}
                        </td>
                    </tr>

                    <tr className='border-t border-b '>
                        <td className="px-4 py-2">Suspect Name</td>
                        <td className="text-left">
                            <a href="#" className="">{data?.suspicious_name}</a>
                        </td>
                    </tr>
                    <tr className='border-b'>
                        <td className="px-4 py-2">Suspect Position</td>
                        <td className="text-left">
                            {data?.suspicious_position}
                        </td>
                    </tr>

                    <tr className='border-b'>
                        <td className="px-4 py-2">Suspect Phone</td>
                        <td className="">
                            {data?.suspicious_phone}
                        </td>
                    </tr>

                    <tr className='border-b'>
                        <td className="px-4 py-2">Attached File</td>
                        <td className="text-left">
                            {data?.attachments?.length > 0 ? "yes" : "no"}
                        </td>
                    </tr>

                    <tr className='border-b'>
                        <td className="px-4 py-2">Reported Date </td>
                        <td className="">
                            {covertdate(data?.created_at)}
                        </td>
                    </tr>

                    {/* <tr className='border-t border-b '>
                        <td className="px-4 py-2">Subject</td>
                        <td className="text-left">
                            <a href="#" className="">{data?.subject}</a>
                        </td>
                    </tr> */}

                    <tr className='border-b'>
                        <td className="px-4 py-2 w-40">Case Detail</td>
                        <td className="text-left py-4">
                            {data?.report_detail}

                             {/* need the element to resemble a link, use a button and change it with appropriate styles. Learn more: */}
                        </td>
                    </tr>

                    <div className='my-16'></div>

                    <tr className='border-t border-b '>
                        <td className="px-4 py-2">Category</td>
                        <td className="text-left">
                            <a href="#" className="">{lastaction?.category}</a>
                        </td>
                    </tr>
                    <tr className='border-t border-b '>
                        <td className="px-4 py-2">Status</td>
                        <td className="text-left">
                            <a href="#" className="">{lastaction?.status}</a>
                        </td>
                    </tr>


                    <tr className='border-b'>
                        <td className="align-middle px-4 py-2">Priority</td>
                        <td>
                            <button
                                // onClick={() => setIsOpen(!isOpen)}
                                className="flex items-center text-gray-500 hover:text-gray-700">
                                {lastaction?.priority == "Big" && <span className="mr-2 rounded-full w-3 h-3 bg-red-700"></span>}
                                {lastaction?.priority == "High" && <span className="mr-2 rounded-full w-3 h-3 bg-orange-400"></span>}
                                {lastaction?.priority == "Normal" && <span className="mr-2 rounded-full w-3 h-3 bg-blue-400"></span>}
                                {lastaction?.priority == "Small" && <span className="mr-2 rounded-full w-3 h-3 bg-green-400"></span>}
                                <span className="text-base">{lastaction?.priority}</span>
                                {/* <svg
                                    className="ml-2 w-4 h-4 text-gray-400 hover:text-gray-600"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.293l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414-1.414L10 12.293l-3.293-3.293a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg> */}

                            </button>

                            {/* //  Dropdown menu  */}
                            {/* {isOpen &&
                                <div
                                    // id="dropdown"
                                    class="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                                >
                                    <ul
                                        className="absolute bg-white rounded-md shadow-sm py-1  text-sm text-gray-700 dark:text-gray-200"
                                    >
                                        <li className="w-full hover:bg-gray-100">
                                            <button type="button" className="px-4 py-2 text-left focus:outline-none flex flex-row items-center">
                                                <div className="mr-2 rounded-full w-3 h-3 bg-red-700"></div>
                                                Big 
                                            </button>
                                        </li>
                                        <li className="w-full hover:bg-gray-100">
                                            <button type="button" className="px-4 py-2 text-left focus:outline-none flex flex-row items-center">
                                                <div className="mr-2 rounded-full w-3 h-3 bg-orange-400"></div>
                                                High
                                            </button>
                                        </li>
                                        <li className="w-full hover:bg-gray-100">
                                            <button type="button" className="px-4 py-2 text-left focus:outline-none flex flex-row items-center">
                                                <div className="mr-2 rounded-full w-3 h-3 bg-blue-400"></div>
                                                Normal
                                            </button>
                                        </li>
                                        <li className="w-full hover:bg-gray-100">
                                            <button type="button" className="px-4 py-2 text-left focus:outline-none flex flex-row items-center">
                                                <div className="mr-2 rounded-full w-3 h-3 bg-green-400"></div>
                                                small
                                            </button>
                                        </li>
                                    </ul>
                                </div>} */}
                        </td>
                    </tr>



                    <div className='my-16'></div>

                    <tr className='border-t border-b'>
                        <td className="px-4 py-2">Assigned by</td>
                        <td className="text-left">
                            <a href="#" className="">
                                {/* {faker.name.firstName()} {faker.name.lastName()} */}
                                {lastaction?.user?.full_name}
                            </a>
                        </td>
                    </tr>

                    <tr className='border-b'>
                        <td className="px-4 py-2">Assigned To</td>
                        <td className="text-left">
                            <a href="#" className="">
                                {/* {faker.name.firstName()} {faker.name.lastName()} */}
                                <div>
                                {lastaction?.assign_to?.full_name}
                                </div>
                                <div>
                                {lastaction?.assign_to?.username}
                                </div>
                            </a>
                            
                        </td>
                    </tr>
                    {/* <tr className='border-t border-b'>
                        <td className="px-4 py-2">Date Assigned</td>
                        <td className="text-left">
                            Wed, 16 Dec 2015, 12:17 PM
                        </td>
                    </tr> */}
                </tbody>
            </table>

            <div className='mt-16 flex flex-row justify-end gap-8 mr-16'>
                <button
                    onClick={() => setReporterOpen(!reporterOpen)}
                    type="button"
                    className="bg-transparent hover:bg-sky-500 dark:text-white  text-xs font-medium uppercase leading-normal hover:text-white py-3 px-4 border border-gray-200 hover:border-transparent rounded"
                >
                    Whistleblower Detail
                </button>
                {/* <button
                    onClick={() => setReplyOpen(!replyOpen)}
                    type="button"
                    className="inline-block rounded bg-sky-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                >
                    Reply
                </button> */}
                <button
                    onClick={() => setTakeActionOpen(!takeActionOpen)}
                    type="button"
                    className="inline-block rounded bg-sky-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                >
                    Change Status
                </button>
               {status == "Process Completed" &&  <button
                    onClick={() => setProcessCompletedOpen(!ProcessCompletedOpen)}
                    type="button"
                    className="inline-block rounded bg-sky-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                >
                    Process Completed
                </button>}
            </div>
            {takeActionOpen && takeAction()}
            {reporterOpen && reporter_Detail()}
            {/* {replyOpen && replyPopup()} */}
            {ProcessCompletedOpen &&
            <ProcessCompletedPopup  complain = {data?.id} onClose={() => setProcessCompletedOpen(false)}/>}

            {/* <button
            onClick={Printdoc}
            className={`shadow-sm hover:drop-shadow-xl bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-50 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
          >Print</button> */}

            <div className='MainSection w-full mt-10'>
                <div className="text-xl font-body mb-3">
                    Attachments
                </div>
                {/* <ul className='ml-10'>
                    {data?.attachments?.map((item) => (
                        <li key={item} className=' '>
                            <a href={item} className='flex flex-row items-center p-2.5 m-2 bg-gray-50  border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'>{getFilenameFromUrl(item)}</a>
                        </li>
                    ))}
                </ul> */}

                <ul className="attachment-list flex flex-wrap items-center justify-between m-2 mt-5 gap-1">

                {data.attachments?.map((item) => {
        const isImage = item.endsWith(".png") || item.endsWith(".PNG") || item.endsWith(".jpg") || item.endsWith(".jpeg") || item.endsWith(".gif");
        const isVideo = item.endsWith(".mp4") || item.endsWith(".webm");
        const pdf = item.endsWith(".pdf") 

        if (isImage) {
          return (
            <li key={item} className="w-1/4"> {/* Set width to 1/3 for each item */}
              <a href={item}>
                <img src={item} alt="Image"  className='object-cover rounded-md'/>
              </a>
              <div className=''>{getFilenameFromUrl(item)}</div>
            </li>
          );
        } else if (isVideo) {
          return (
            <li key={item} className="w-1/3"> {/* Set width to 1/3 for each item */}
              <a href={item}>
                <video src={item}  autoPlay muted className="h-full w-full object-cover rounded-md" />
              </a>
              <div className=''>{getFilenameFromUrl(item)}</div>
            </li>
          );
        } else if (pdf) {
            return (
              <li key={item} className="w-1/3"> {/* Set width to 1/3 for each item */}
                 <button onClick={() => openInNewTab(item)}>
                   <FontAwesomeIcon icon={faFilePdf} size="3x" className="fa-fw " />
                   </button>
                <div className=''>{getFilenameFromUrl(item)}</div>
              </li>
            );
          } else {
          return (
            <li key={item} className="w-1/3"> {/* Set width to 1/3 for each item */}
              <a href={item}>
                {/* <span>Unknown attachment ({item.split(".").pop()})</span> */}
                <FontAwesomeIcon icon={faFile} size="3x" className="fa-fw " />
              </a>
              <div className=''>{getFilenameFromUrl(item)}</div>
            </li>
             
          );
        }
       
      })}
          
        </ul>
            </div>
            {/* {data?.attachments.map((item, index) => (
          <div key={index} className="flex items-center leading-8 gap-5 border-b-1 border-color p-3">
             <img className="rounded-full h-10 w-10" src={item.image} alt={item.message} /> 
            <div>
              <p className="font-semibold dark:text-gray-200">item.message</p>
              <p className="text-gray-500 text-sm dark:text-gray-400"> item.desc </p>
            </div>
          </div>
        ))} */}

             {/* {reply?.length > 0 && <Reply reply={reply}/>} */}
            

        </div>
    )
}

export default Case_Detail