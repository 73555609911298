import React, { useEffect, useState } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css'; // Or other theme CSS
// import faker from '@faker-js/faker/locale/en_US';
import { faker } from '@faker-js/faker';
import Dropzone from "./Dropzone";
import { GLOBALTYPES } from '../../redux/actions/globalTypes';
import { createAction} from '../../redux/actions/caseAction';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

const Editor = () => {

  const { quill, quillRef } = useQuill();


    const { id } = useParams();
    const dispatch = useDispatch();
    const { auth } = useSelector((state) => state);

    const [content, setContent] = useState(``);
    const [dragDrop, setDragDrop] = useState(``);

    console.log('quill content', content)

    useEffect(() => {
      if (quill) {
       // quill.clipboard.dangerouslyPasteHTML('<h1> TiTle</h1><ol><li>asdfasdf oreder list</li><li>sadfaf</li><li>asdfas</li></ol><p>asdfjhaslkjdfhajkshdfkjh pargtaph</p><h2> Responcebility</h2><ul><li>unorderlist</li><li>sdfsd</li><li>asdfsa</li></ul><h3> color</h3><p><strong style="background-color: rgb(0, 138, 0);">asdfasfasdfasdfsdfasdfasdfsfadf</strong></p><p><span style="color: rgb(178, 178, 0);">asdfsdfasddaasdfasdfasdfasdfadf</span></p><p><u style="color: rgb(178, 178, 0);">aasdfasdfasfadfsfaasfdfasfasfd</u></p>');
        quill.clipboard.dangerouslyPasteHTML(content);
        // setContent(quill.root.innerHTML)
      }
     
    }, [quill]);

    const modules = {
        toolbar: [
          [{ header: [1, 2, false] }], // Headings (H1, H2, none)
          ['bold', 'italic', 'underline', 'strike', 'blockquote'], // Basic formatting
          [{ list: 'ordered' }, { list: 'bullet' }], // Ordered and unordered lists
          [{ indent: '-1' }, { indent: '+1' }], // Indentation
          ['clean'], // Clear formatting
        ],
      };
    
      const formats = [
        'header',  // Required for headings functionality
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
      ];

    const SubmitAction = (e) => {
      e.preventDefault();

      console.log('content', content)
      console.log('dragDrop', dragDrop.length)
      const editor = quill.root.innerHTML
      console.log('editor', editor)
      if (editor == '<p><br></p>' && dragDrop.length == 0)
        return dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: 'Please add a file or write something in the editor.' },
        });

      const actiondata = new FormData();
      if (dragDrop?.length){
        dragDrop.forEach(file => actiondata.append('attachments', file))
      }
      {editor != "<p><br></p>" && actiondata.append('editor', editor)}
      actiondata.append("complain", id)
      actiondata.append("user", auth.user.id)

      dispatch(createAction({ actiondata, auth }));
    }

    return (
        <div style={{ margin: 50}}>
          <div style={{ minWidth: 250, minheight: 250 }} className='bg-white'>
                   <div ref={quillRef}
                     value={quill}
                     />
                </div>

        {/* <ReactQuill value={content} onChange={setContent} 
          modules={modules}
          formats={formats}
          style={{ Height: '4008px' }} 
        /> */}
         <Dropzone className='p-10 mt-10 border-2 border-neutral-300 border-dashed' setDragDrop={setDragDrop}/>
        <div className=' flex justify-end'>
        <button type="submit"
                                    onClick={SubmitAction}
                                    class="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    {/* <svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg> */}
                                    Save
                                </button>
                                </div>
      
      </div>
  )
};

export default Editor;

// import React, { useState } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

// // import {
// //     Container,
// //     Card
// // } from './../../../components';

// // import { HeaderMain } from "../../components/HeaderMain";

// export class Editor extends React.Component {
    
//     state = {
//         text: `
//         `
//     }

//     modules = {
//         toolbar: [
//             [{ 'header': [1, 2, false] }],
//             ['bold', 'italic', 'underline','strike', 'blockquote'],
//             [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
//             ['clean']
//         ],
//     }

//     formats = [
//         'header',
//         'bold', 'italic', 'underline', 'strike', 'blockquote',
//         'list', 'bullet', 'indent'
//     ]

//     render() {
//         return (
//             <div>
//                 {/* <HeaderMain 
//                     title="Editor"
//                     className="mb-5 mt-4"
//                 /> */}
//                 {/* <p>
//                     <strong>Quill</strong> is a modern rich text editor built for compatibility and extensibility.
//                 </p> */}
//                 <div>
//                     <ReactQuill
//                         value={ this.state.text }
//                         onChange={ this._handleChange }
//                         modules={ this.modules }
//                         formats={ this.formats }
//                         style={{
//                             minHeight: '480px'
//                         }}
//                     />
//                 </div>
//             </div>
//         );
//     }

//     // _handleChange = (text) => {
//     //     this.setState({ text })
//     // }
// }
