import React, { useEffect, useState } from "react";
import "./style.css";

function DynamicInputFields({ suspects, onFormDataChange }) {
  // const [inputs, setInputs] = useState([
  //   { name: '', age: '', date: '' }, // Initial empty input object
  // ]);

  console.log("suspects", suspects)

  const [inputs, setInputs] = useState([
    {
      finding: "",
      name: "",
      eeu_id: "",
      position: "",
      action_taker_department: "",
      posted_date: "",
      action_taken: "",
      ethical_opinion: "",
    }, // Initial empty input object
  ]);

  useEffect(() => {

    if (suspects.length > 0){
    const initialInputs = suspects.map((suspect) => ({
      finding: suspect.finding || "", // Use suspect.finding if available, otherwise set to ""
      name: suspect.name || "",
      eeu_id: suspect.eeu_id || "",
      position: suspect.position || "",
      action_taker_department: suspect.action_taker_department || "",
      posted_date: suspect.posted_date || "",
      action_taken: suspect.action_taken || "",
      ethical_opinion: suspect.ethical_opinion || "",
    }));

    setInputs(initialInputs);

    if (onFormDataChange) {
      onFormDataChange(initialInputs); // Pass updated inputs to parent
    }
    }
  }, [suspects]);


  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    if (name === "date") {
      const formattedDate = formatDate(value); // Call the formatDate function
      const updatedInputs = [...inputs];
      updatedInputs[index][name] = formattedDate;
      setInputs(updatedInputs);

      // Update parent component with the latest data (optional)
      if (onFormDataChange) {
        onFormDataChange(updatedInputs); // Pass updated inputs to parent
      }
    } else {
      const updatedInputs = [...inputs];
      updatedInputs[index][name] = value;
      setInputs(updatedInputs);

      // Update parent component with the latest data (optional)
      if (onFormDataChange) {
        onFormDataChange(updatedInputs); // Pass updated inputs to parent
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (!isNaN(date.getTime())) {
      // Check if the date is valid
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Pad month with 0
      const day = String(date.getDate()).padStart(2, "0"); // Pad day with 0
      return `${year}-${month}-${day}`;
    } else {
      return ""; // Return empty string if date is invalid
    }
  };

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      {
        finding: "",
        name: "",
        eeu_id: "",
        position: "",
        action_taker_department: "",
        posted_date: "",
        action_taken: "",
        ethical_opinion: "",
      },
    ]); // Add a new empty object
  };

  const handleRemoveInput = (index) => {
    if (window.confirm(`Are you sure you want to remove`)) {
      if (inputs.length > 1) {
        const updatedInputs = [...inputs];
        updatedInputs.splice(index, 1);
        setInputs(updatedInputs);

        // Update parent component with the latest data (optional)
        if (onFormDataChange) {
          onFormDataChange(updatedInputs); // Pass updated inputs to parent
        }
      }
    }
  };

  return (
    <div>
      {/* <h3 class="text-lg font-semibold text-gray-900 dark:text-white border-b border-red-700 my-4">
        Dynamic Input Fields
                        </h3> */}
      <form>
        {inputs.map((input, index) => (
          <div key={index} class="MainSection border-b  my-4">
            <div class="grid gap-y-3 gap-x-12 mb-4 mx-3  grid-cols-12">
              <div class="col-span-6">
                <label
                  for="finding"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  ከተጣራ በኋላ በማስረጃ የተረጋገጠው ግኝት በአጭሩ
                </label>
                <textarea
                  id={`finding${index}`}
                  rows="3"
                  name="finding"
                  value={input.finding}
                  onChange={(event) => handleInputChange(event, index)}
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write here"
                ></textarea>
              </div>
              <div class="col-span-6"></div>
              <div class="col-span-6">
                <label
                  for="region"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  ጥፋቱን የፈጸሙት ተጠያቂ /ሰራተኛ/ ኃላፊ/ ስምና መለያ ቁጥር
                </label>
                <div>
                  <div class="flex justify-center items-center mb-4">
                    <label
                      for="name"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white mr-4"
                    >
                      ስም :
                    </label>
                    <input
                      type="text"
                      name="name"
                      id={`name${index}`}
                      value={input.name}
                      onChange={(event) => handleInputChange(event, index)}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-1/2 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder=""
                      required=""
                    />
                  </div>
                  <div class="flex justify-center items-center">
                    <label
                      for="eeu_id"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white mr-4"
                    >
                      መለያ ቁጥር :
                    </label>
                    <input
                      type="text"
                      name="eeu_id"
                      id={`eeu_id${index}`}
                      value={input.eeu_id}
                      onChange={(event) => handleInputChange(event, index)}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-1/2 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder=""
                      required=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-span-6">
                {/* <label for="region" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ጥፋቱን የፈጸሙት ተጠያቂ /ሰራተኛ/ ኃላፊ/  ስምና መለያ ቁጥር</label>
                                <div> */}
                <div class="flex justify-center items-center mb-4">
                  <label
                    for="position"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white mr-4"
                  >
                    የሥራ መደብ :
                  </label>
                  <input
                    type="text"
                    name="position"
                    id={`position${index}`}
                    value={input.position}
                    onChange={(event) => handleInputChange(event, index)}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-1/2 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder=""
                    required=""
                  />
                </div>
                <div class="flex justify-center items-center mb-4">
                  <label
                    for="action_taker_department"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    <div>እርምጃ እንዲወስዱ በደብዳቤ</div>
                    <div>የተጻፈለት ክፍል</div>
                  </label>
                  <div class="mr-4">: </div>
                  <input
                    type="text"
                    name="action_taker_department"
                    id={`action_taker_department${index}`}
                    value={input.action_taker_department}
                    onChange={(event) => handleInputChange(event, index)}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-1/2 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder=""
                    required=""
                  />
                </div>
                <div class="flex justify-center items-center">
                  <label
                    for="posted_date"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    <div>እርምጃ እንዲወሰድ ደብዳቤ</div>
                    <div>ወጪ የሆነበት ቀን</div>
                  </label>
                  <div class="mr-4">: </div>
                  <input
                    type="date"
                    name="posted_date"
                    id={`posted_date${index}`}
                    value={input.posted_date}
                    onChange={(event) => handleInputChange(event, index)}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-1/2 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder=""
                    required=""
                  />
                </div>
                {/* </div> */}
              </div>

              <div class="col-span-6">
                <label
                  for="action_taken"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  የተወሰዱ እርምጃዎች
                </label>
                <textarea
                  id={`action_taken${index}`}
                  rows="3"
                  name="action_taken"
                  value={input.action_taken}
                  onChange={(event) => handleInputChange(event, index)}
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write here"
                ></textarea>
              </div>

              <div class="col-span-6">
                <label
                  for="ethical_opinion"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  የሥነምግባር አስተያየት
                </label>
                <textarea
                  id={`ethical_opinion${index}`}
                  rows="3"
                  name="ethical_opinion"
                  value={input.ethical_opinion}
                  onChange={(event) => handleInputChange(event, index)}
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write here"
                ></textarea>
              </div>

              {index > 0 && ( // Only show remove button for non-first inputs
                <div className="flex">
                  <button
                    type="button"
                    onClick={() => handleRemoveInput(index)}
                    //    class="text-lg font-semibold text-gray-900 dark:text-white"
                    class="text-white  bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </form>
      <div className="flex">
        <button
          type="button"
          onClick={handleAddInput}
          class="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Add Input
        </button>
      </div>
    </div>
  );
}

export default DynamicInputFields;
