
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { ExcelExport, Inject, Toolbar } from '@syncfusion/ej2-react-grids';
// import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getTableProcessCompleted } from "../redux/actions/caseAction";
// import { useEffect } from 'react';
// import { columnSpanData } from './datasource';

import { IoMdSearch } from "react-icons/io";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Header } from "../components";
import { utils, writeFile } from 'xlsx';
import { dateSearch } from '../redux/actions/caseAction';

function All_Completed() {
    const { startDate, endDate } = useParams();
    console.log("title", startDate, endDate);

    const { auth, cases } = useSelector((state) => state);
    const dispatch = useDispatch();

    // const editing = { allowDeleting: true, allowEditing: true };

    const [completedData, setCompletedData] = React.useState("");

    // console.log("completedData", completedData);

    useEffect(() => {
        if (auth.token) {
            // dispatch(getTableDashboard(itemstring,startDate,endDate,auth.token));
            dispatch(getTableProcessCompleted(startDate, endDate, auth.token));
        }
    }, [dispatch, auth.token]);

    useEffect(() => {
        // const desiredData = [];

        // for (const report of cases.completedTable) {
        //     for (const suspect of report.suspects) {
        //         const newSuspect = { ...suspect }; // Copy suspect data
        //         newSuspect.user = report.user;
        //         newSuspect.complain = report.complain;
        //         newSuspect.region = report.region;
        //         newSuspect.incident_location = report.incident_location;
        //         newSuspect.suspects_length = report.suspects_length;
        //         newSuspect.number_responsible_parties =
        //             report.number_responsible_parties;

        //         // ... Add other report properties as needed
        //         newSuspect.Suggestion = report.Suggestion;

        //         desiredData.push(newSuspect);
        //     }
        // }

        const desiredData = [];

        for (const report of cases.completedTable) {
          const firstSuspect = report.suspects[0];  // Access the first suspect
          const newSuspect = { ...firstSuspect };   // Copy first suspect data
        
          // Add additional report properties to newSuspect
          newSuspect.user = report.user;
          newSuspect.complain = report.complain;
          newSuspect.region = report.region;
          newSuspect.incident_location = report.incident_location;
          newSuspect.suspects_length = report.suspects_length;
          newSuspect.number_responsible_parties = report.number_responsible_parties;
          newSuspect.Suggestion = report.Suggestion;  // Include Suggestion property
        
          // Add the modified first suspect to desiredData
          desiredData.push(newSuspect);
        
          // For remaining suspects (if any)
          for (let i = 1; i < report.suspects.length; i++) {
            const otherSuspect = report.suspects[i];
        
            // Option 1: Directly add remaining suspects with only their own properties
            desiredData.push(otherSuspect);
        
          }
        }

        console.log("desiredData DDDDDDDDDDD", desiredData);

        setCompletedData(desiredData);
    }, [cases.completedTable]);

    const pageOptions = {
        pageSize: 20,
    };
    const settings = { type: "Multiple" };

    let grid;
    const toolbar = ['ExcelExport'];
    const toolbarClick = (args) => {
        if (grid && args.item.id === 'Grid_excelexport') {
            grid.excelExport();
        }
    };

    const queryCellInfoEvent = (args) => {
        const col = args.column;
        const data = args.data;
        if ([
            "complain.id",
            "region",
            "incident_location",
            "number_responsible_parties",
            "Suggestion"
        ].includes(col.field)) {
            args.rowSpan = data.suspects_length;
        } else {
            args.rowSpan = 1; // Set rowSpan to 1 for all other columns
        }
        
  };

    const [cases1, setCases1] = useState("");
    const [query, setQuery] = useState('');

    const [selectedDate, setSelectedDate] = useState(null);

    function handleSearch() {

        // dispatch(caseSearch(query, auth.token))
        console.log("query1", query, auth.token)
    }


    function handleDateSearch(date) {
        setSelectedDate(date)
        if (auth.token && date) {
            const offsetInHours = 3;
            date.setHours(date.getHours() + offsetInHours);
            const formattedDate = date.toISOString().split("T")[0];
            dispatch(dateSearch(formattedDate, auth.token))
        }
    }

    // const Export = () => {
    //     console.log("aaaaaaaaaaaaaaaaa", desiredData)
    //     const wb = utils.book_new();
    //     const ws = utils.json_to_sheet(desiredData);
    //     utils.book_append_sheet(wb, ws, "MySheet1");
    //     writeFile(wb, "Check.xlsx");
    // }

   

    return (
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
            <div className="md:flex  justify-between">
                <div className="flex gap-10">
                    <Header category="Page" title="Process Completed" />
                </div>

                <div
                    style={{
                        padding: 16,
                        borderBottomWidth: 1,
                        borderColor: "#f0f0f0",
                    }}
                    className="md:flex items-center"
                >
                    {/* {auth.user?.role == "admin" && (
                        <div className="mr-5">
                            <button
                                onClick={Export}
                                className={`shadow-sm hover:drop-shadow-xl bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                            >
                                Export
                            </button>
                        </div>
                    )} */}
                    <div className="mr-5">
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => handleDateSearch(date)}
                            placeholderText="Search by Reported date"
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        ></DatePicker>
                    </div>
                    <div style={{ position: "relative" }}>
                        <input
                            style={{
                                backgroundColor: "#e1e2e4",
                                height: 42,
                                borderRadius: 26,
                                padding: 16,
                                fontSize: 16,
                                paddingLeft: 50,
                            }}
                            value={query}
                            onChange={(event) => setQuery(event.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                            placeholder="Search..."
                            placeholderTextColor="#b0b0b0"
                        />
                        <IoMdSearch
                            style={{
                                fontSize: "25px",
                                color: "#505050",
                                position: "absolute",
                                left: 18,
                                top: 10,
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="control-pane">
                <div className="control-section">
                    <GridComponent
                        dataSource={completedData}
                        ref={(g) => (grid = g)}
                        allowPaging={true}
                        pageSettings={pageOptions}
                        selectionSettings={settings}
                        queryCellInfo={queryCellInfoEvent}
                        allowTextWrap={true}
                        height="auto"
                        width="200%"
                        gridLines="Both"
                        id='Grid' toolbar={toolbar} allowExcelExport={true} toolbarClick={toolbarClick}
                    >
                        <ColumnsDirective>
                            {/* <ColumnDirective
                                field="id"
                                headerText="ID"
                                width="70"
                                textAlign="Center"
                            /> */}
                            <ColumnDirective
                                field="complain.id"
                                headerText="Case ID"
                                width="90"
                                textAlign="Center"
                            />
                            <ColumnDirective field="region" headerText="ሪጅን" width="120" />
                            <ColumnDirective
                                field="incident_location"
                                headerText="ችግሩ የተከሰተበት ቦታ"
                                width="120"
                            />
                            <ColumnDirective
                                field="Suggestion"
                                headerText="የቀረበው ጥቆማ"
                                width="200"
                            />
                            <ColumnDirective
                                field="finding"
                                headerText="ከተጣራ በኋላ በማስረጃ የተረጋገጠው ግኝት በአጭሩ"
                                width="400"
                            />
                            <ColumnDirective
                                field="number_responsible_parties"
                                headerText="ተጠያቂ አካላት ብዛት"
                                width="90"
                            />
                            <ColumnDirective field="name" headerText="ጥፋቱን የፈጸሙት ተጠያቂ /ሰራተኛ/ ኃላፊ/  ስም" width="150" />
                            <ColumnDirective field="eeu_id" headerText="ጥፋቱን የፈጸሙት ተጠያቂ /ሰራተኛ/ ኃላፊ/ መለያ ቁጥር" width="150" />
                            <ColumnDirective
                                field="position"
                                headerText="የሥራ መደብ"
                                width="150"
                            />
                            <ColumnDirective
                                field="action_taker_department"
                                headerText="እርምጃ  እንዲወስዱ በደብዳቤ የተጻፈለት ክፍል"
                                width="150"
                            />
                            <ColumnDirective
                                field="posted_date"
                                headerText="እርምጃ እንዲወሰድ ደብዳቤ ወጪ የሆነበት ቀን "
                                width="150"
                            />
                            <ColumnDirective
                                field="action_taken"
                                headerText="የተወሰዱ እርምጃዎች"
                                width="150"
                                textAlign="Right"
                            />
                            <ColumnDirective
                                field="ethical_opinion"
                                headerText="የሥነምግባር አስተያየት"
                                width="120"
                            />
                        </ColumnsDirective>
                        <Inject services={[Toolbar, ExcelExport]}/>
                    </GridComponent>
                </div>
            </div>
        </div>
    );
}
export default All_Completed;
