// export const BASE_URL = 'http://localhost:8000'


// export const socket_URL = 'ws://localhost:8000'

// export const BASE_URL = 'http://192.168.0.4:8000'

export const socket_URL = 'ws://antiback.zsecretacademy.com'


// export const BASE_URL = process.env.REACT_APP_API_KEY
export const BASE_URL = 'https://antiback.zsecretacademy.com'