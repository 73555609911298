import React, { useEffect, useState } from 'react'
import DynamicInputFields from './DynamicInputFields';
import { createProcessCompleted, getProcessCompleted_byid } from '../../redux/actions/caseAction';
import { useSelector, useDispatch } from "react-redux";

function ProcessCompletedPopup({complain, onClose}) {

    const {auth, cases } = useSelector((state) => state);
  const dispatch = useDispatch();

    const [input, setInput] = useState(
        {
            region: "", incident_location: "", Suggestion: "", number_responsible_parties: "" 
        }, // Initial empty input object
      );

      const [suspects, setSuspectsReport] = useState([])

      const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setInput({...input, [name]: value });
    };

    useEffect(() => {
        if (auth.token) {
          dispatch(getProcessCompleted_byid(complain, auth.token));
        }
      }, [dispatch, auth.token, complain]);

    useEffect(() => {
        setInput({...input, region: cases?.ProcessCompletedbyid?.region, 
            incident_location: cases?.ProcessCompletedbyid?.incident_location, 
            Suggestion: cases?.ProcessCompletedbyid?.Suggestion,
            number_responsible_parties: cases?.ProcessCompletedbyid?.number_responsible_parties  });

        setSuspectsReport(cases.SuspectsReportbyid)

      }, [cases.ProcessCompletedbyid, cases.SuspectsReportbyid]);
   
    const SubmitProcessCompleted = (e) => {
        e.preventDefault();

        const ProcessCompletedData = {
            complain: complain, 
            user: auth.user.id, 
            region: input.region || '', 
            incident_location: input.incident_location || '',
            Suggestion: input.Suggestion || '',
            number_responsible_parties: parseInt(input.number_responsible_parties) || 0, 
            formData_length: formData.length || 0,
            formData: formData,
        }
            
        dispatch(createProcessCompleted({ProcessCompletedData, auth }));


      

       } 

       const handleClose = () => {
        onClose && onClose(); // Call provided onClose callback if available
      };

      const [formData, setFormData] = useState([]); // State to store input data

      const handleFormDataChange = (updatedData) => {
        setFormData(updatedData); // Update state with received data from child
        console.log('Form data updated:', updatedData); // Optional: Log data for debugging
      };

    return (
        // <!-- Main modal -->
        <div id="crud-modal" tabindex="-1" aria-hidden="true" class="bg-half-transparent overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div class="relative p-4 w-11/12 max-h-full">
                {/* <!-- Modal content --> */}
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 ">
                    {/* <!-- Modal header --> */}
                    <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                            Process Completed
                        </h3>
                        <button 
                           onClick={handleClose}
                            type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <form class="p-4 md:p-5">
                        <div class="grid gap-y-2 gap-x-12 mb-4 mx-3  grid-cols-12">

                            <div class="col-span-6">
                                <label for="region" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ሪጅን</label>
                                <input type="text" name="region" id="region" 
                                        value={input.region}
                                        onChange={handleChangeInput}
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="" required="" />
                            </div>
                            <div class="col-span-6">
                                <label for="incident_location" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ችግሩ የተከሰተበት ቦታ</label>
                                <input type="text" name="incident_location" id="incident_location"  
                                        //    onChange={(e) => setReplySubject(e.target.value)}
                                        //    value={replySubject}
                                        value={input.incident_location}
                                        onChange={handleChangeInput}
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="" required="" />
                            </div>

                            <div class="col-span-6">
                                <label for="Suggestion" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">የቀረበው ጥቆማ</label>
                                <textarea id="Suggestion" rows="3" name='Suggestion'
                                    // onChange={(e) => setReplyBody(e.target.value)}
                                    // value={replyBody}
                                    value={input.Suggestion}
                                    onChange={handleChangeInput}
                                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write here"></textarea>
                            </div>

                            <div class="col-span-6 sm:col-span-3">
                    <label for="number_responsible_parties" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ተጠያቂ አካላት ብዛት</label>
                    <input type="number" name="number_responsible_parties" id="number_responsible_parties" 
                       value={input.number_responsible_parties}
                       onChange={handleChangeInput}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="" required=""/>
                </div>

                            {/* <div class="col-span-6">
                                <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Body</label>
                                <textarea id="description" rows="5" name='body'
                                    // onChange={(e) => setReplyBody(e.target.value)}
                                    // value={replyBody}
                                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write here"></textarea>
                            </div> */}
                        </div> 
                        <DynamicInputFields suspects={suspects} onFormDataChange={handleFormDataChange} />
                        <div className='flex justify-end'>
                            <button type="submit"
                                onClick={SubmitProcessCompleted}
                                class="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                {/* <svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg> */}
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ProcessCompletedPopup