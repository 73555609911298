import { Header, Button2, Add_Staff, Check_Popup } from "../components";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
import avatar from "../data/avatar.jpg";
import avatar2 from "../data/avatar2.jpg";
import avatar3 from "../data/avatar3.png";
import avatar4 from "../data/avatar4.jpg";
import { GrView } from "react-icons/gr";
import { MdOutlineDeleteForever } from "react-icons/md";
import { IoMdSearch } from "react-icons/io";
import { useStateContext } from "../contexts/ContextProvider";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { contextMenuItems } from "../data/dummy";
import { useSelector, useDispatch } from "react-redux";
import { getCheckList } from "../redux/actions/checkAction";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { utils, writeFile } from 'xlsx';
import { getCases, deleteCase, caseSearch, dateSearch } from '../redux/actions/caseAction';
const ExcelJS = require("exceljs");

const All_Cases= () => {
  // const [open, setOpen] = useState(false);
  const { currentColor, currentMode, handleClick, isClicked } =
    useStateContext();
  const toolbarOptions = ["Search"];

  const editing = { allowDeleting: true, allowEditing: true };

  const { auth, cases } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [cases1, setCases1] = useState("");
  const [query, setQuery] = useState('')
  // const [device, setDevice] = useState("");
  // const [employee, setEmployee] = useState("");

  const [selectedDate, setSelectedDate] = useState(null);

  //console.log("selectedDate", selectedDate);

  // console.log("cases1", cases1);

  useEffect(() => {
    setCases1(cases.cases.results);
    // setDevice(check?.checkList?.device);
    // setEmployee(check?.checkList?.employee);
  }, [cases]);

  useEffect(() => {
    if (auth.token) {
      // const formattedDate = selectedDate.toISOString().split("T")[0];
      // dispatch(getCheckList(formattedDate, auth.token));
      dispatch(getCases(auth.token));
    }
  }, [dispatch, auth.token]);

  function handleSearch() {
   
    dispatch(caseSearch(query, auth.token))
    console.log("query1", query, auth.token)
  }


  function handleDateSearch(date) {
    setSelectedDate(date)
    if (auth.token && date) {
      const offsetInHours = 3;
      date.setHours(date.getHours() + offsetInHours);
      const formattedDate = date.toISOString().split("T")[0];
      dispatch(dateSearch(formattedDate, auth.token))
    }
  }

  console.log("cases1", cases1)

  const Export = () => {
    // console.log("aaaaaaaaaaaaaaaaa", cases1)
    // const wb = utils.book_new();
    // const ws = utils.json_to_sheet(cases1);
    // utils.book_append_sheet(wb, ws, "MySheet1");
    // writeFile(wb, "Check.xlsx");

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 50;

    sheet.getRow(1).border = {
      top: { style: "thick", color: { argb: "FFFF0000" } },
      left: { style: "thick", color: { argb: "000000FF" } },
      bottom: { style: "thick", color: { argb: "F08080" } },
      right: { style: "thick", color: { argb: "FF00FF00" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      // pattern: "darkVertical",
      pattern:"solid",
      fgColor: { argb: "FFFF00" },
    };

    sheet.getRow(1).font = {
      name: "Comic Sans MS",
      family: 4,
      size: 13,
      bold: true,
    };

    sheet.columns = [
      {
        header: "Id",
        key: "id",
        width: 10,
      },
      { header: "phone", key: "phone_number", width: 15 },
      {
        header: "Location",
        key: "location",
        width: 20,
      },
      {
        header: "Report Detail",
        key: "report_detail",
        width: 40,
      },

      {
        header: "Incident Date",
        key: "incident_happend_Date",
        width: 20,
      },
      {
        header: "responsible CSC",
        key: "eeu_office",
        width: 20,
      },
      {
        header: "Suspect name",
        key: "suspicious_name",
        width: 20,
      },
      {
        header: "Suspect postion",
        key: "suspicious_position",
        width: 20,
      },
      {
        header: "Suspect phone",
        key: "suspicious_phone",
        width: 20,
      },




      {
        header: "Repoted at",
        key: "created_at",
        width: 18,
      },
      {
        header: "Category",
        key: "category",
        width: 15,
      },
      {
        header: "Status",
        key: "status",
        width: 15,
      },

      {
        header: "Priority",
        key: "priority",
        width: 15,
      },
      {
        header: "Assigned To",
        key: "assign_to",
        width: 15,
      },
    ];

    cases1?.map(item => {
      // const rowNumber = index + 1;
      sheet.addRow({
        id: item?.id,
        phone_number: item?.phone_number,
        location: item?.location,
        created_at: new Date(item?.created_at).toLocaleString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',   
        }),
        category: item?.lastactions.category,
        status: item?.lastactions.status,
        priority: item?.lastactions.priority,
        assign_to: item?.lastactions?.assign_to?.username,

        report_detail: item?.report_detail,
        incident_happend_Date:item?.incident_happend_Date,
        eeu_office: item?.eeu_office,
        suspicious_name:item?.suspicious_name,
        suspicious_position:item?.suspicious_position,
        suspicious_phone:item?.suspicious_phone,
        // rating: product?.rating,
      })
      });

    workbook.xlsx.writeBuffer().then(cases1 => {
      const blob = new Blob([cases1], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "download.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="md:flex  justify-between">
        <div className="flex gap-10">
          <Header category="Page" title="All Cases" />
          {/* <div className="mt-3">
            <Button2
              color="white"
              bgColor={currentColor}
              text="Check"
              borderRadius="10px"
              customFunc={() => handleClick("check")}
            />
          </div> */}
        </div>

        <div
          style={{
            padding: 16,
            borderBottomWidth: 1,
            borderColor: "#f0f0f0",
          }}
          className="md:flex items-center"
            >
              { auth.user?.role == 'admin' && <div className="mr-5">
          <button
            // selected={selectedDate}
            // onChange={(date) => setSelectedDate(date)}
            onClick={Export}
            className={`shadow-sm hover:drop-shadow-xl bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
          >Export</button>
          </div>}
              <div className="mr-5">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => handleDateSearch(date)}
            // showIcon
            placeholderText="Search by Reported date"
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          ></DatePicker>
          </div>
          <div style={{ position: "relative" }}>
            <input
              style={{
                backgroundColor: "#e1e2e4",
                height: 42,
                borderRadius: 26,
                padding: 16,
                fontSize: 16,
                paddingLeft: 50,
              }}
              value={query}
            onChange={(event) => setQuery(event.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
              placeholder="Search..."
              placeholderTextColor="#b0b0b0"
            />
            <IoMdSearch
              style={{
                fontSize: "25px",
                color: "#505050",
                position: "absolute",
                left: 18,
                top: 10,
              }}
            />
          </div>
        </div>
      </div>
      <GridComponent
        id="gridcomp"
        dataSource={cases1}
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        contextMenuItems={contextMenuItems}
        editSettings={editing}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {ordersGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject
          services={[
            Resize,
            Sort,
            ContextMenu,
            Filter,
            Page,
            ExcelExport,
            Edit,
            PdfExport,
          ]}
        />
      </GridComponent>

      {/* {isClicked.check && (<Check_Popup />)} */}
    </div>
  );
};

export default All_Cases;

export const ordersData = [
  {
    Case_number: "1",
    Category: "corruption",
    incident_title: "corruption",
    Status: "Pending",
    Priority: 'High',
    Repoted_at: "Friday, 05-04-2021",
    Location: "east 7",
    Assigned_to: "Abebe",
  },
  // {
  //   id: "2",
  //   Name: "Emily Nguyen",
  //   status: 0,
  //   Checked_at: "Friday, 05-04-2021",
  //   Type: "LapTop",
  //   Brand: "HP",
  //   Serial_number: "4A185d48w",
  //   reason: "",
  //   Gender: "F",
  //   Phone: "0987654321",
  //   Email: "name@gmail.com",
  //   EEU_Id: "579340",
  //   Job_Title: "Product Manager",
  //   Department: "Marketing",
  //   EmployeeImage: avatar4,
  // },
  // {
  //   id: "3",
  //   Name: "Jonathan Lee",
  //   status: 0,
  //   Checked_at: "Friday, 05-04-2021",
  //   Type: "LapTop",
  //   Brand: "HP",
  //   Serial_number: "4A185d48w",
  //   reason: "",
  //   Gender: "M",
  //   Phone: "0987654321",
  //   Email: "name@gmail.com",
  //   EEU_Id: "579341",
  //   Job_Title: "Data Scientist",
  //   Department: "Analytics",
  //   EmployeeImage: avatar2,
  // },
  // {
  //   id: "4",
  //   Name: "Anna Smith",
  //   status: 1,
  //   Checked_at: "Friday, 05-04-2021",
  //   Type: "LapTop",
  //   Brand: "HP",
  //   Serial_number: "4A185d48w",
  //   reason: "",
  //   Gender: "F",
  //   Phone: "0987654321",
  //   Email: "name@gmail.com",
  //   EEU_Id: "579342",
  //   Job_Title: "Graphic Designer",
  //   Department: "Design",
  //   EmployeeImage: avatar,
  // },
];

const gridEmployeeProfile = (props) => (
  <Link to={`/staff/${props.id}`}>
  <div className="flex items-center gap-2">
    <img
      className="rounded-full w-10 h-10"
      src={props.employee?.thumbnail}
      alt="employee"
    />
    <p>
      {props.employee?.first_name} {props.employee?.last_name}
    </p>
  </div>
  </Link>
);

export const gridOrderImage = (props) => (
  <div>
    {props.status ? (
      <FontAwesomeIcon
        icon="fa-sign-in"
        size={30}
        color="#20d080"
        style={{
          marginRight: 10,
        }}
      />
    ) : (
      <FontAwesomeIcon
        icon="fa-sign-out"
        size={30}
        color="#F85B5B"
        style={{
          marginRight: 10,
        }}
      />
    )}
  </div>
);

export const gridOrderStatus = (props) => (
  <div>
    <label>{props.status ? "checked in" : "checked out"}</label>
  </div>
);

const gridChecked_at = (props) => {
  const createdAt = new Date(props.created_at)
  const day = createdAt.getDate().toString().padStart(2, '0');
  const month = (createdAt.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
  const year = createdAt.getFullYear().toString().slice(2);
  
  const formattedDate = `${day}/${month}/${year}`;

  return (
  <div>
    <label>
      {/* {props.created_at &&
        new Date(props.created_at).toLocaleString("en-US", { hour12: true })} */}
        {formattedDate}
    </label>
  </div>
  );
};

const gridPriority = (props) => (
      <div  className="flex items-center text-gray-500 hover:text-gray-700">
                                {props.lastactions?.priority == "Big" && <span className="mr-2 rounded-full w-3 h-3 bg-red-700"></span>}
                                {props.lastactions?.priority == "High" && <span className="mr-2 rounded-full w-3 h-3 bg-orange-400"></span>}
                                {props.lastactions?.priority == "Normal" && <span className="mr-2 rounded-full w-3 h-3 bg-blue-400"></span>}
                                {props.lastactions?.priority == "Small" && <span className="mr-2 rounded-full w-3 h-3 bg-green-400"></span>}
                                <span className="text-base">{props.lastactions?.priority}</span>
                                </div>
)

const GridCaseAction = (props) => {
  // Access state and dispatch outside the component's JSX
  const { auth, homePosts } = useSelector((state) => state);
  const dispatch = useDispatch();

  let id = props.id

  return (
    <div className="flex items-center justify-center gap-2">
      <Link to={`/case/${props.id}`}>
        <GrView style={{ fontSize: '20px', color: 'green' , marginRight:20 }} />
      </Link>

      {auth.user?.role == 'admin' && <div onClick={() => {
        if (window.confirm(`Are you sure you want to delete ${props.first_name}?`)) {
           dispatch(deleteCase(id, auth)); // Pass props.id and auth directly
           //console.log("ID AC",id)
        }
      }}>
        <MdOutlineDeleteForever style={{ fontSize: '25px', color: 'red' }} />
      </div>}
    </div>
  );
};

export const ordersGrid = [
  {
    field: "id",
    headerText: "Id",
    width: "90",
    // template: gridEmployeeProfile,
    textAlign: "Center",
  },
  {
    field: "phone_number",
    headerText: "phone",
    // template: gridOrderImage,
    textAlign: "Center",
    width: "120",
  },
  
  // {
  //   field: "subject",
  //   headerText: "Subject",
  //   // template: gridOrderImage,
  //   textAlign: "Center",
  //   width: "120",
  // },
  // {
  //   field: "Priority",
  //   headerText: "Priority",
  //   // template: gridOrderStatus,
  //   width: "150",
  //   textAlign: "Center",
  // },
  {
    field: "location",
    headerText: "Location",
    textAlign: "Center",
    width: "150",
  },
  {
    field: "created_at",
    headerText: "Repoted at",
    template: gridChecked_at,
    width: "100",
    textAlign: "Center",
  },
  
  {
    field:'lastactions.category',
    headerText: "Category",
    // template: gridOrderImage,
    textAlign: "Center",
    width: "120",
  },
   {
      field: "lastactions.status",
      headerText: "Status",
      // template: gridOrderStatus,
      width: "100",
      textAlign: "Center",
    },
    {
      field: "lastactions.priority",
      headerText: "Priority",
      template: gridPriority,
      width: "100",
      textAlign: "Center",
    },
  {
    field: "lastactions.assign_to.username",
    headerText: "Assigned To",
    textAlign: "Center",
    width: "150",
  },
  { headerText: 'Action',
      width: '150',
      template: GridCaseAction,
      textAlign: 'Center' },

  
  // {
  //   field: "device.model",
  //   headerText: "Model",
  //   width: "120",
  //   textAlign: "Center",
  // },

  // {
  //   field: "device.serial_number",
  //   headerText: "Serial_number",
  //   width: "150",
  //   textAlign: "Center",
  // },
  // {
  //   field: "barcode",
  //   headerText: "barcode",
  //   width: "150",
  //   textAlign: "Center",
  // },
  // {
  //   field: "reason",
  //   headerText: "reason",
  //   width: "150",
  //   textAlign: "Center",
  // },
];
