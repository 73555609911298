import { Dashboard } from '@mui/icons-material';
import { CASE_TYPES } from '../actions/caseAction'
import { EditData, DeleteData } from '../actions/globalTypes'

const initialState = {
    loading: false,
    cases: '',
    case: '',
    whistleblower: '',
    device: '',
    reply: [],
    lastaction: '',
    action: [],
    registered_users: [],
    dashboard:'',
    dashboardTable: [],
    ProcessCompletedbyid: '',
    SuspectsReportbyid: [],
    completedTable: []
}

const casesReducer = (state = initialState, action) => {
    switch (action.type){
        // case CASE_TYPES.CREATE_CASE:
        //     return {
        //         ...state,
        //         cases: action.payload
        //     };
        
        case CASE_TYPES.LOADING_CASE:
            return {
                ...state,
                loading: action.payload
            };
        case CASE_TYPES.GET_CASES:
            return {
                ...state,
                // cases: action.payload.cases,
                // result: action.payload.result,
                // page: action.payload.page
                
                cases: action.payload,
            };
        case CASE_TYPES.GET_CASE:
            return {
                ...state,
                // cases: action.payload.cases,
                // result: action.payload.result,
                // page: action.payload.page
                
                case: action.payload.complain,
                whistleblower: action.payload.whistleblower,
                device: action.payload.device,
                action: action.payload.action,
                lastaction: action.payload.lastaction,
                registered_users: action.payload.registered_users,
                reply: action.payload.reply,
            };
        // case CASE_TYPES.UPDATE_CASE:
        //     return {
        //         ...state,
        //         cases: EditData(state.cases, action.payload.id, action.payload)
        //     };
        // case CASE_TYPES.DELETE_CASE:
        //     return {
        //         ...state,
        //         cases: DeleteData(state.cases, action.payload.id)
        //     };

        case CASE_TYPES.CREATE_ACTIONCASE:
            return {
                ...state,
                action: [action.payload.action, ...state.action],
                lastaction: action.payload.lastaction,
            };
            
        case CASE_TYPES.CREATE_REPLY:
            return {
                ...state,
                reply: [action.payload, ...state.reply],
            };

        case CASE_TYPES.GET_DASHBOARD:
            return {
                ...state,
                dashboard: action.payload
            };

        case CASE_TYPES.GET_TABLEDASHBOARD:
            return {
                ...state,
                dashboardTable: action.payload
            };
        case CASE_TYPES.DELETE_ACTION:
            return {
                ...state,
                action: DeleteData(state.action, action.payload.id)
            };
            
        case CASE_TYPES.GET_PROCESSCOMPLETEDBYID:
            return {
                ...state,

                ProcessCompletedbyid: action.payload.ProcessCompleted,
                SuspectsReportbyid: action.payload.SuspectsReport,
            };

        case CASE_TYPES.GET_TABLECOMPLETED:
            return {
                ...state,

                completedTable: action.payload,
            };

        default:
            return state;
    }
}

export default casesReducer