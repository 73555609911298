import Case_Detail from '../components/case/Case_Detail';
import Timeline from '../components/case/Timeline';
import RightSide from '../components/message/RightSide ';
import LeftSide from '../components/message/LeftSide';

import { getCase_byid } from '../redux/actions/caseAction';
import { deleteCase } from '../redux/actions/caseAction';
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useStateContext } from '../contexts/ContextProvider';
import Case_File from '../components/case/Case_File';

const Case = () => {

    const { id } = useParams();
    const { currentColor, currentMode, handleClick, isClicked } = useStateContext();
    const editing = { allowDeleting: true, allowEditing: true };
  
    const { auth, cases } = useSelector((state) => state);
    const dispatch = useDispatch();
  
    const [data, setdata] = useState("");
    // const [whistleblower, setWhistleblower] = useState("");
    // const [device, setDevice] = useState("");
    const [action, setAction] = useState([]);
    const [lastAction, setLastAction] = useState("");
    const [registeredUsers, setRegisteredUsers] = useState("");
    // const [reply, setReply] = useState([]);


    // console.log("registeredUsersrrrrrrrrrrrrrrrrrrr", registeredUsers)
  
    useEffect(() => {
      setdata(cases?.case)
      // setWhistleblower(cases?.whistleblower)
      // setDevice(cases?.device)
      setAction(cases?.action)
      setLastAction(cases?.lastaction)
      setRegisteredUsers(cases?.registered_users)
      // setReply(cases?.reply)

    //   if (cases?.action.length > 0){
    //     const action1 = cases?.action
      
    //     const largestObject = action1.reduce((largest, current) => {
    //         return current.id > largest.id ? current : largest;
    //       }, action1[0])
    //      setLargestAction(largestObject)}
      }, [cases, cases.action, cases.lastAction]);
  
    // const [staff, setStaff] = useState(true);
  
    // const [deviceItem, setDeviceItem] = useState("");
  
    //console.log("staff_id", staff)
   
  
    useEffect(() => {
      if (auth.token) {
        dispatch(getCase_byid(id, auth.token));
        // dispatch(getdevice_byid(id, auth.token));
        // dispatch(getemployee_byid(id, auth.token));
      }
    }, [dispatch, auth.token, id]);
  
    // useEffect(() => {
    //    if (check?.employee?.staff){
    //      setStaff(true)
    //    }
    //    else { setStaff(false) }
  
    // }, [cases]);
  

    


    return (
        <React.Fragment>
            {/* <HeaderMain
                    title="Tasks Details"
                    className="mb-5 mt-4"
                /> */}
            { /* START Header 1 */}

            <div className="xl:grid grid-cols-12 gap-2">
                <div className="col-span-7 ">
                    {data && <Case_Detail data={data}  lastaction={lastAction} registeredUsers={registeredUsers} />}
                </div>

               <div className="col-span-5 mt-24 flex justify-center">
                    {action.length > 0 && <Timeline action={action}/>}

        
                </div>

            </div>
            {data && <Case_File/>}


            {/* <div className='mx-20'>
            <div className="message flex">
                         <div className="w-4/12 border-r px-0 left_mess">
                             <LeftSide />
                         </div>
             
                         <div className="w-8/12 px-0">
                             <RightSide />
                         </div>
                         </div>         
        </div> */}

        </React.Fragment >
    )
}

export default Case