import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Resize,
    Sort,
    ContextMenu,
    Filter,
    Page,
    ExcelExport,
    PdfExport,
    Edit,
    Inject,
  } from "@syncfusion/ej2-react-grids";
  import { contextMenuItems } from "../data/dummy";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { Link } from "react-router-dom";
  import { useParams } from "react-router-dom";
  import { getTableDashboard } from '../redux/actions/caseAction';
  import { MdOutlineDeleteForever } from "react-icons/md";
  import { getCases, deleteCase, caseSearch, dateSearch } from '../redux/actions/caseAction';
  import { GrView } from 'react-icons/gr';

function InPremises() {

  const { itemstring, startDate, endDate  } = useParams();
  console.log("title", itemstring, startDate, endDate )

    const { auth, cases  } = useSelector((state) => state);
    const dispatch = useDispatch();

    const editing = { allowDeleting: true, allowEditing: true };

    const [Inside, setInside] = useState("");

    console.log("Inside", Inside)

    useEffect(() => {
        dispatch(getTableDashboard(itemstring,startDate,endDate,auth.token));
      }, []);

      useEffect(() => {
        setInside(cases.dashboardTable.results);
      }, [cases.dashboardTable]);

    return (
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
            <div className="md:flex  justify-between">

            </div>
            <GridComponent
                id="gridcomp"
                dataSource={Inside}
                allowPaging
                allowSorting
                allowExcelExport
                allowPdfExport
                contextMenuItems={contextMenuItems}
                editSettings={editing}
            >
                <ColumnsDirective>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    {ordersGrid.map((item, index) => (
                        <ColumnDirective key={index} {...item} />
                    ))}
                </ColumnsDirective>
                <Inject
                    services={[
                        Resize,
                        Sort,
                        ContextMenu,
                        Filter,
                        Page,
                        ExcelExport,
                        Edit,
                        PdfExport,
                    ]}
                />
            </GridComponent>
        </div>
    )
}

export default InPremises



const gridcreated_at = (props) => {
    const createdAt = new Date(props.created_at)
    const day = createdAt.getDate().toString().padStart(2, '0');
    const month = (createdAt.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
    const year = createdAt.getFullYear().toString().slice(2);
    
    const formattedDate = `${day}/${month}/${year}`;
  
    return (
    <div>
      <label>
        {/* {props.created_at &&
          new Date(props.created_at).toLocaleString("en-US", { hour12: true })} */}
          {formattedDate}
      </label>
    </div>
    );
  };

const gridPriority = (props) => (
    <div  className="flex items-center text-gray-500 hover:text-gray-700">
                              {props.lastactions?.priority == "Big" && <span className="mr-2 rounded-full w-3 h-3 bg-red-700"></span>}
                              {props.lastactions?.priority == "High" && <span className="mr-2 rounded-full w-3 h-3 bg-orange-400"></span>}
                              {props.lastactions?.priority == "Normal" && <span className="mr-2 rounded-full w-3 h-3 bg-blue-400"></span>}
                              {props.lastactions?.priority == "Small" && <span className="mr-2 rounded-full w-3 h-3 bg-green-400"></span>}
                              <span className="text-base">{props.lastactions?.priority}</span>
                              </div>
)

const GridCaseAction = (props) => {
// Access state and dispatch outside the component's JSX
const { auth, homePosts } = useSelector((state) => state);
const dispatch = useDispatch();

let id = props.id

return (
  <div className="flex items-center justify-center gap-2">
    <Link to={`/case/${props.id}`}>
      <GrView style={{ fontSize: '20px', color: 'green' , marginRight:20 }} />
    </Link>

    {auth.user?.role == 'admin' && <div onClick={() => {
      if (window.confirm(`Are you sure you want to delete ${props.first_name}?`)) {
         dispatch(deleteCase({id, auth})); // Pass props.id and auth directly
         //console.log("ID AC",id)
      }
    }}>
      <MdOutlineDeleteForever style={{ fontSize: '25px', color: 'red' }} />
    </div>}
  </div>
);
};
  
  

  export const ordersGrid = [
    {
      field: "id",
      headerText: "Case number",
      width: "100",
      // template: gridEmployeeProfile,
      textAlign: "Center",
    },
    {
      field: "phone_number",
      headerText: "phone number",
      // template: gridOrderImage,
      textAlign: "Center",
      width: "120",
    },
    
    // {
    //   field: "subject",
    //   headerText: "Subject",
    //   // template: gridOrderImage,
    //   textAlign: "Center",
    //   width: "120",
    // },
    // {
    //   field: "Priority",
    //   headerText: "Priority",
    //   // template: gridOrderStatus,
    //   width: "150",
    //   textAlign: "Center",
    // },
    {
      field: "location",
      headerText: "Location",
      textAlign: "Center",
      width: "150",
    },
    {
      field: "created_at",
      headerText: "Repoted at",
      template: gridcreated_at,
      width: "100",
      textAlign: "Center",
    },
    
    {
      field:'lastactions.category',
      headerText: "Category",
      // template: gridOrderImage,
      textAlign: "Center",
      width: "120",
    },
     {
        field: "lastactions.status",
        headerText: "Status",
        // template: gridOrderStatus,
        width: "100",
        textAlign: "Center",
      },
      {
        field: "lastactions.priority",
        headerText: "Priority",
        template: gridPriority,
        width: "100",
        textAlign: "Center",
      },
    {
      field: "lastactions.assign_to.username",
      headerText: "Assigned To",
      textAlign: "Center",
      width: "150",
    },
    { headerText: 'Action',
        width: '150',
        template: GridCaseAction,
        textAlign: 'Center' },
  
    
    // {
    //   field: "device.model",
    //   headerText: "Model",
    //   width: "120",
    //   textAlign: "Center",
    // },
  
    // {
    //   field: "device.serial_number",
    //   headerText: "Serial_number",
    //   width: "150",
    //   textAlign: "Center",
    // },
    // {
    //   field: "barcode",
    //   headerText: "barcode",
    //   width: "150",
    //   textAlign: "Center",
    // },
    // {
    //   field: "reason",
    //   headerText: "reason",
    //   width: "150",
    //   textAlign: "Center",
    // },
  ];