import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Avatar, Box, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { grey } from '@mui/material/colors';
import { gridClasses } from '@mui/x-data-grid';
import avatar4 from '../data/avatar4.jpg';
import { Link } from "react-router-dom";
import { GrView } from 'react-icons/gr';
import { MdOutlineDeleteForever } from "react-icons/md";
import { Header } from '../components';

import { useSelector, useDispatch } from "react-redux";
import { IoMdSearch } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCases, deleteCase } from '../redux/actions/caseAction';

const gridEmployeeAction = (props) => (
  <div className="flex items-center justify-center gap-2">
    <Link to={`/staff/${props.id}`}>
      <GrView style={{ fontSize: '20px', color: 'green' }}/>
    </Link>
     <MdOutlineDeleteForever style={{ fontSize: '25px', color: 'red' }} />
  </div>
);

const gridChecked_at = (props) => (
  <div>
    <label>
      {props.created_at &&
        new Date(props.created_at).toLocaleString("en-US", { hour12: true })}
    </label>
  </div>
);

const GridEmployeeAction = (props) => {
  // Access state and dispatch outside the component's JSX
  const { auth, homePosts } = useSelector((state) => state);
  const dispatch = useDispatch();

  let id = props.id

  return (
    <div className="flex items-center justify-center gap-2">
      <Link to={`/case/${props.Case_number}`}>
        <GrView style={{ fontSize: '20px', color: 'green' , marginRight:20 }} />
      </Link>

      {auth.user?.role == 'admin' && <div onClick={() => {
        if (window.confirm(`Are you sure you want to delete ${props.first_name}?`)) {
           dispatch(deleteCase({id, auth})); // Pass props.id and auth directly
           //console.log("ID AC",id)
        }
      }}>
        <MdOutlineDeleteForever style={{ fontSize: '25px', color: 'red' }} />
      </div>}
    </div>
  );
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field:'category',
    headerName: "Category",
    // template: gridOrderImage,
    // textAlign: "Center",
    width: 520,
  },
  {
    field: "subject",
    headerText: "Subject",
    // template: gridOrderImage,
    textAlign: "Center",
    width: "120",
  },
  // {
  //   field: "Status",
  //   headerText: "Status",
  //   // template: gridOrderStatus,
  //   width: "150",
  //   textAlign: "Center",
  // },
  // {
  //   field: "Priority",
  //   headerText: "Priority",
  //   // template: gridOrderStatus,
  //   width: "150",
  //   textAlign: "Center",
  // },
  {
    field: "created_at",
    headerText: "Repoted at",
    template: gridChecked_at,
    width: "200",
    textAlign: "Center",
  },
  {
    field: "location",
    headerText: "Location",
    textAlign: "Center",
    width: "150",
  },
  // {
  //   field: "Assigned_to",
  //   headerText: "Assigned To",
  //   textAlign: "Center",
  //   width: "150",
  // },
  { headerText: 'Action',
      width: '150',
      template: GridEmployeeAction,
      textAlign: 'Center' },

  // {
  //   field: 'photoURL',
  //   headerName: 'Avatar',
  //   width: 60,
  //   renderCell: (params) => <Avatar src={params.row.photoURL} />,
  //   sortable: false,
  //   filterable: false,
  // },
  // {
  //   field: 'firstName',
  //   headerName: 'First name',
  //   width: 150,
  //   editable: true,
  // },
  // {
  //   field: 'lastName',
  //   headerName: 'Last name',
  //   width: 150,
  //   editable: true,
  // },
  // {
  //   field: 'age',
  //   headerName: 'Age',
  //   type: 'number',
  //   width: 110,
  //   editable: true,
  // },
  // {
  //   field: 'fullName',
  //   headerName: 'Full name',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params: GridValueGetterParams) =>
  //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  // },
  // { 
  //     headerName: 'Action',
  //     renderCell: (params) => 
  //     <div className="flex items-center justify-center gap-2">
  //     <Link to={`/staff/${params.row.id}`}>
  //       <GrView style={{ fontSize: '20px', color: 'green' }}/>
  //     </Link>
  //      <MdOutlineDeleteForever style={{ fontSize: '25px', color: 'red' }} />
  //   </div>, },
];


const rows = [
  // { id: 1, photoURL:avatar4 , lastName: 'Snow', firstName: 'Jon', age: 14 },
  // { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
  // { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
  // { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
  // { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  // { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  // { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  // { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  // { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  // { id: 1, photoURL:avatar4 , lastName: 'Snow', firstName: 'Jon', age: 14 },
  // { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
  // { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
  // { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
  // { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  // { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  // { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  // { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  // { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  // { id: 1, photoURL:avatar4 , lastName: 'Snow', firstName: 'Jon', age: 14 },
  // { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
  // { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
  // { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
  // { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  // { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  // { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  // { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  // { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];



const All_Staff = () => {

  const { auth, cases } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(10);
  const [rowId, setRowId] = useState(null);
  const [page, setPage] = useState(1);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [cases1, setCases1] = useState([]);

  //console.log("paaaaaaaaaage", page)

  console.log("case1aaaaaaaaaage", page)

  useEffect(() => {
    setCases1(cases?.cases?.results);
  }, [cases]);

  useEffect(() => {
    if (auth.token) {
      // const formattedDate = selectedDate.toISOString().split("T")[0];
      // dispatch(getCheckList(formattedDate, auth.token));
      dispatch(getCases(auth.token));
    }
  }, [dispatch, auth.token, selectedDate]);

  const Export = () => {
    // console.log("aaaaaaaaaaaaaaaaa", cases1)
    // const wb = utils.book_new();
    // const ws = utils.json_to_sheet(cases1);
    // utils.book_append_sheet(wb, ws, "MySheet1");
    // writeFile(wb, "Check.xlsx");
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="md:flex  justify-between">
        <div className="flex gap-10">
          <Header category="Page" title="All_Cases" />
        </div>

        <div
          style={{
            padding: 16,
            borderBottomWidth: 1,
            borderColor: "#f0f0f0",
          }}
          className="md:flex items-center"
            >
              { auth.user?.role == 'admin' && <div className="mr-5">
          <button
            onClick={Export}
            className={`shadow-sm hover:drop-shadow-xl bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
          >Export</button>
          </div>}
              <div className="mr-5">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          ></DatePicker>
          </div>
          <div style={{ position: "relative" }}>
            <input
              style={{
                backgroundColor: "#e1e2e4",
                height: 42,
                borderRadius: 26,
                padding: 16,
                fontSize: 16,
                paddingLeft: 50,
              }}
              // value={query}
              // onChangeText={setQuery}
              placeholder="Search..."
              placeholderTextColor="#b0b0b0"
            />
            <IoMdSearch
              style={{
                fontSize: "25px",
                color: "#505050",
                position: "absolute",
                left: 18,
                top: 10,
              }}
            />
          </div>
        </div> 
      </div>
    <Box sx={{ height: 720, width: '100%' }}>
      {/* <Typography
        variant="h3"
        component="h3"
        sx={{ textAlign: 'center', mt: 3, mb: 3 }}
      >
        Manage Users
      </Typography> */}
      <DataGrid
        // autoHeight {...cases1}
        autoHeight
        rows={cases1? cases1 : []}
        columns={columns}
        getRowId={(row) => row.id}
        rowsPerPageOptions={[5, 10, 20]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => setPage(newPage)}
        getRowSpacing={(params) => ({
          top: params.isFirstVisible ? 0 : 3,
          bottom: params.isLastVisible ? 0 : 3,
        })}
        sx={{
          [`& .${gridClasses.row}`]: {
            bgcolor: (theme) =>
              theme.palette.mode === 'light' ? grey[200] : grey[900],
          },
        }}
        onCellEditCommit={(params) => setRowId(params.id)}
      />
    </Box>
    </div>
  )
}

export default All_Staff